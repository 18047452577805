import { FULFILLED } from 'redux-promise-middleware';

import { PRODUCT_ACTIONS } from '../utils/constants/ActionTypes';


/**
 * Version compatibilities reducer to organize the version compatibilities list that is returned from the API
 *
 * @param state - Existing redux state for the version compatibility list
 * @param type - Action type that this reducer should listen for
 * @param data - payload data that is returned from the API for version compatibilities call
 *
 * @returns {{M1: {EE: Array, CE: Array}, M2: {EE: Array, CE: Array, ECE: Array}}|void} - Object constructed of the
 * returned live version compatibilities for a user
 */
export default (state = {M1:{CE:[], EE:[]}, M2:{CE:[], EE:[], ECE:[]}}, {type, payload:{data = []} = {}}) => {
    switch (type) {
        case PRODUCT_ACTIONS.GET_PRODUCT_VERSION_COMPATIBILITIES + "_" + FULFILLED : {

            //combine and reduce the data payload into something the views will use
            data.forEach(({platform, edition, live}) => { state[platform][edition] = live });
            break;
        }
        default : {
            return state;
        }
    }

    return state;
};