
import { REST_URLS_PRODUCT_API , URL_PARAMS, REST_URLS_USER_PROFILE_API, REST_URLS_REPORT_API, REST_URLS_FILE_UPLOADS_API, REST_URLS_MESSAGES_API } from '../constants/URL_Addresses';

const { GET_USER, GET_SALES_REPORT, GET_REFUND_REPORT, FORMAT_CSV } = REST_URLS_REPORT_API;

export const getUserProductListURL = (offset='0',limit='-1',type='all', style='summary') => `${REST_URLS_PRODUCT_API.GET_USER_PRODUCT_LIST}${(style ? `?type=${type}&offset=${offset.toString()}&limit=${limit.toString()}&style=${style.toString()}` : `?type=${type}&offset=${offset.toString()}&limit=${limit.toString()}`)}`;

export const getUserProductItemBySubmissionIdURL = (submissionId) => REST_URLS_PRODUCT_API.GET_USER_PRODUCT_ITEM_BY_ID + submissionId;

// export const getUserProductItemBySubmissionIdforSPURL = (submissionId) => REST_URLS_PRODUCT_API.GET_USER_PRODUCT_ITEM_BY_ID + submissionId;

export const createProductURL = () => REST_URLS_PRODUCT_API.POST_USER_PRODUCT_ITEM;
export const createFirstProductVersionURL = () => REST_URLS_PRODUCT_API.PUT_USER_PRODUCT_ITEM;

export const createUploadIdURL = () => REST_URLS_FILE_UPLOADS_API.POST_FILE_UPLOADS;

export const updateProductURL = () => REST_URLS_PRODUCT_API.PUT_USER_PRODUCT_ITEM;

export const updateProductBySubmissionIdURL = (submissionId) =>  `${REST_URLS_PRODUCT_API.PUT_USER_PRODUCT_ITEM}/${submissionId}`;

export const getUserProductListByUrlKeyURL = (url_key) => REST_URLS_PRODUCT_API.GET_USER_PRODUCT_LIST_BY_URL_KEY + url_key;

// sortProductVersions 
export const getSortedProductVersionsURL = (url_key, order, elm) => REST_URLS_PRODUCT_API.GET_USER_PRODUCT_LIST_BY_URL_KEY + url_key + REST_URLS_PRODUCT_API.SORT_USER_PRODUCT+ order + elm;

// export const getCheckIfPatchVersionURL = (url_key,order,elm) => REST_URLS_PRODUCT_API.GET_USER_PRODUCT_LIST_BY_URL_KEY + url_key + REST_URLS_PRODUCT_API.SORT_USER_PRODUCT+ order + elm;

export const getProductVersionCompatibilitiesURL = (submissionId) => REST_URLS_PRODUCT_API.GET_USER_PRODUCT_ITEM_BY_ID + submissionId + REST_URLS_PRODUCT_API.GET_PRODUCT_VERSION_COMPATIBILITIES;

export const getProductTestReportStatusURL = (submissionId) => REST_URLS_PRODUCT_API.GET_USER_PRODUCT_ITEM_BY_ID + submissionId + REST_URLS_PRODUCT_API.GET_PRODUCT_TEST_REPORTS;

export const getUserProductItemBySubmissionIdForAdminURL = (submissionId) => REST_URLS_PRODUCT_API.GET_USER_PRODUCT_ITEM_BY_ID + submissionId + REST_URLS_PRODUCT_API.GET_PRODUCT_PREVIEW + URL_PARAMS.AS_ADMIN;

export const getUserProductPreviewURL = (submissionId) => REST_URLS_PRODUCT_API.GET_USER_PRODUCT_ITEM_BY_ID + submissionId + REST_URLS_PRODUCT_API.GET_PRODUCT_PREVIEW;

export const getUserDataURL = (summary=false) => `${REST_URLS_USER_PROFILE_API.GET_USER_PROFILE}${(summary ? "?style=summary" : "")}`;

export const getProductTestReportURL = (submissionId, testName, testId) => REST_URLS_PRODUCT_API.GET_USER_PRODUCT_ITEM_BY_ID + submissionId + REST_URLS_PRODUCT_API.GET_PRODUCT_TEST_REPORTS + `/${testName}/${testId}?view=true`;

export const getReportSalesCSVURL = (mageId, offset='0', limit='-1') => GET_USER + mageId + GET_SALES_REPORT + FORMAT_CSV + `&offset=${offset.toString()}&limit=${limit.toString()}`;

export const getReportRefundsCSVURL = (mageId, offset='0', limit='-1') => GET_USER + mageId + GET_REFUND_REPORT + FORMAT_CSV + `&offset=${offset.toString()}&limit=${limit.toString()}`;

export const getMessagePoliciesURL = () => REST_URLS_MESSAGES_API.GET_POLICIES;

export const getMessageAnnouncementsURL = () => REST_URLS_MESSAGES_API.GET_ANNOUNCEMENTS;

export const getLiveProductsURL = () => REST_URLS_PRODUCT_API.GET_LIVE_PRODUCTS;
