import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";

import TOSDeclined from "./TOSDeclined";
import TOSAccept from "./TOSAccept";
import { updateUserDataPromise } from "../../../actions/user";

import {URL_ADDRESSES} from "../../../utils/constants/URL_Addresses";


class TOSModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show_decline_modal : false,
            cb_selected        : false
        };
    }

    handleContinueButtonClicked = () => {
        this.props.updateUserDataPromise({has_accepted_tos:true})
            .then(data => {

                //this line below should work, but the react update lifecycle isn't getting trigger//
                //TODO//look into why the auth route doesnt see a change in the userData to re-render//JRP//
                //this.props.history.push(URL_ADDRESSES.INTERNAL_HOME);

                //this hack is in here, because sometimes when props are updated, they arent reflecting here.  this most likely
                //needs to be updatd to use the react lifecycle, and then update should work fine//JRP//
                window.location.href = URL_ADDRESSES.INTERNAL_EXTENSIONS;
            })
    };

    handleCancelButtonClicked = () => {
        this.setState({show_decline_modal:true})
    };

    handleReturnButton = () => {
        this.setState({show_decline_modal:false})
    };

    render() {
        return (
            <div className="w-fit">
                <TOSAccept show={true}
                           handleCancelButton={this.handleCancelButtonClicked}
                           handleContinueButton={this.handleContinueButtonClicked} />
                <TOSDeclined show={this.state.show_decline_modal}
                             handleContinueButton={this.handleReturnButton} />
            </div>
        );
    }
}

TOSModal.propTypes = {
    history               : PropTypes.object.isRequired,
    updateUserDataPromise : PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({ updateUserDataPromise : data => ( dispatch( updateUserDataPromise(data)) )});

export default withRouter(connect(null, mapDispatchToProps)(TOSModal));