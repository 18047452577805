import React from 'react';

const FileLoadingManagerContext = React.createContext();

export class FileLoadingManagerContextProvider extends React.Component {
    state = {
        filesLoadingList: [],
        addFileIsLoading: (fileID) => {
            const newList = Array.from(new Set([...this.state.filesLoadingList, fileID])); // Ensure no duplicates. Overkill but defensive.
            this.setState({ filesLoadingList: newList });
        },
        removeFileIsLoading: (fileID) => {
            this.setState({
                filesLoadingList: this.state.filesLoadingList.filter(file=>(file!==fileID)),
            })
        },
        removeAllFilesLoading: () => {
            this.setState({filesLoadingList:[]})
        }
    }

    render() {
        return (
            <FileLoadingManagerContext.Provider value={this.state}>
                {this.props.children}
            </FileLoadingManagerContext.Provider>
        )
    }
}



export const FileLoadingManagerContextConsumer = FileLoadingManagerContext.Consumer;
export default FileLoadingManagerContext;
