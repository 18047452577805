export const NOTIFICATION_ACTIONS = Object.freeze({
    CLEAR_NOTIFICATIONS : "NOTIFICATION_ACTIONS__CLEAR_NOTIFICATIONS",
    ERROR_NOTIFICATION  : "NOTIFICATION_ACTIONS__ERROR_NOTIFICATION",
    CLEAR_ERROR_NOTIFICATION : "NOTIFICATION_ACTIONS__CLEAR_ERROR_NOTIFICATION",
    FORM_ERROR          : "FORM_ERROR",
    CLEAR_FORM_ERROR    : "CLEAR_FORM_ERROR",
    CLEAR_PRODUCT_FORM_ERRORS: "CLEAR_PRODUCT_FORM_ERRORS",
    PRODUCT_SUBMIT_SUCCESS_NOTIFICATION : "PRODUCT_SUBMIT_SUCCESS_NOTIFICATION",
    CLEAR_PRODUCT_SUBMIT_SUCCESS_NOTIFICATION: "CLEAR_PRODUCT_SUBMIT_SUCCESS_NOTIFICATION",
});

export const NOTIFICATION_LEVELS = Object.freeze({
    INFO         : 3, //Blue box, just to give info
    NOTIFICATION : 2, //Green box, should be to notify the user of an action that took place
    WARNING      : 1, //Yellow Box, notify user of a warning thats not a show stopper
    ERROR        : 0  //Red Box, probably a critical error or server error
});

export const USER_ACTIONS = Object.freeze({
    LOGIN                    : "USER_ACTIONS__LOGIN",
    LOGOUT                   : "USER_ACTIONS__LOGOUT",
    GET_USER_PROFILE_DATA    : "USER_ACTIONS__GET_USER_PROFILE_DATA",
    GET_USER_DATA            : "USER_ACTIONS__GET_USER_DATA",
    UPDATE_USER_PROFILE_DATA : "USER_ACTIONS__UPDATE_USER_PROFILE_DATA",
    UPDATE_USER_DATA         : "USER_ACTIONS__UPDATE_USER_DATA",
    GET_USER_ENV_DATA        : "USER_ACTIONS__GET_USER_ENV_DATA"
});

export const NAVIGATION_ACTIONS = Object.freeze({
    NAVIGATE_TO_URL : "NAVIGATION_ACTIONS__NAVIGATE_TO_URL"
});

export const USER_ACCESS_KEYS_ACTIONS = Object.freeze({
    GET_USER_ACCESS_KEYS   : "USER_ACCESS_KEYS_ACTIONS__GET_USER_ACCESS_KEYS",
    CREATE_USER_ACCESS_KEY : "USER_ACCESS_KEYS_ACTIONS__CREATE_USER_ACCESS_KEY",
    UPDATE_USER_ACCESS_KEY : "USER_ACCESS_KEYS_ACTIONS__UPDATE_USER_ACCESS_KEY",
    DELETE_USER_ACCESS_KEY : "USER_ACCESS_KEYS_ACTIONS__DELETE_USER_ACCESS_KEY",
});

export const USER_API_KEYS_ACTIONS = Object.freeze({
    GET_USER_API_KEYS   : "USER_API_KEYS_ACTIONS__GET_USER_API_KEYS",
    CREATE_USER_API_KEY : "USER_API_KEYS_ACTIONS__CREATE_USER_API_KEY",
    REGENERATE_USER_API_KEY : "USER_API_KEYS_ACTIONS__REGENERATE_USER_API_KEY",
    DELETE_USER_API_KEY : "USER_API_KEYS_ACTIONS__DELETE_USER_API_KEY",
});

export const VENDOR_ACTIONS = Object.freeze({
    CHECK_VENDOR_NAME_EXISTS : "VENDOR_ACTIONS__CHECK_VENDOR_NAME_EXISTS",
});

export const FILE_UPLOAD_ACTIONS = Object.freeze({
    GET_UPLOADED_FILES                   : "FILE_UPLOAD_ACTIONS__GET_UPLOADED_FILES",
    UPLOAD_USER_PROFILE_IMAGE            : "FILE_UPLOAD_ACTIONS__UPLOAD_USER_PROFILE_IMAGE",
    UPLOAD_PRODUCT_PACKAGE               : "FILE_UPLOAD_ACTIONS__UPLOAD_PRODUCT_PACKAGE",
    UPLOAD_PACKAGE                       : "FILE_UPLOAD_ACTIONS__UPLOAD_PACKAGE",
    DELETE_FILE_UPLOAD_BY_FILE_UPLOAD_ID : "FILE_UPLOAD_ACTIONS__DELETE_FILE_UPLOAD_BY_FILE_UPLOAD_ID",
    UPDATE_FILE_UPLOAD                   : "FILE_UPDATE_FILE_UPLOAD",
    REMOVE_FILE_UPLOAD                   : "FILE_REMOVE_FILE_UPLOAD",
    UPDATE_ARTIFACT_LOAD_LIST            : "FILE_UPDATE_ARTIFACT_LOAD_LIST",
    REMOVE_ARTIFACT_LOAD_ITEM            : "FILE_REMOVE_ARTIFACT_LOAD_ITEM",
});

export const PRODUCT_ACTIONS = Object.freeze({
    GET_USER_PRODUCT_LIST               : "PRODUCT_ACTIONS__GET_USER_PRODUCT_LIST",
    GET_USER_PRODUCT_ITEM_BY_ID         : "PRODUCT_ACTIONS__GET_USER_PRODUCT_ITEM_BY_ID",
    GET_USER_PRODUCT_ITEM_BY_ID_FOR_SP  : "PRODUCT_ACTIONS__GET_USER_PRODUCT_ITEM_BY_ID_FOR_SP",  //TEMP : hotfix for SP version details to work
    CREATE_USER_PRODUCT_ITEM            : "PRODUCT_ACTIONS__CREATE_USER_PRODUCT_ITEM",
    UPDATE_USER_PRODUCT_ITEM            : "PRODUCT_ACTIONS__UPDATE_USER_PRODUCT_ITEM",
    GET_USER_PRODUCT_LIST_BY_URL_KEY    : "PRODUCT_ACTIONS__GET_USER_PRODUCT_LIST_BY_URL_KEY",
    SORT_USER_PRODUCT_VERSIONS          : "PRODUCT_ACTIONS__SORT_USER_PRODUCT_VERSIONS",
    GET_CHECK_IF_PATCH_VERSION          : "PRODUCT_ACTIONS__GET_CHECK_IF_PATCH_VERSION",
    GET_PRODUCT_VERSION_COMPATIBILITIES : "PRODUCT_ACTIONS__GET_PRODUCT_VERSION_COMPATIBILITIES",
    GET_PRODUCT_CATEGORIES              : "PRODUCT_ACTIONS__GET_PRODUCT_CATEGORIES",
    GET_PRODUCT_TEST_REPORTS            : "PRODUCT_ACTIONS__GET_PRODUCT_TEST_REPORTS",
    GET_PRODUCT_TEST_REPORT             : "PRODUCT_ACTIONS__GET_PRODUCT_TEST_REPORT",
    GET_USER_PRODUCT_PREVIEW            : "PRODUCT_ACTIONS__GET_USER_PRODUCT_PREVIEW",
    GET_USER_PRODUCT_PREVIEW_FOR_ADMIN  : "PRODUCT_ACTIONS__GET_USER_PRODUCT_PREVIEW_FOR_ADMIN",
});

export const REPORT_ACTIONS = Object.freeze({
    GET_PRODUCT_DETAILS_VIEW           : "REPORT_ACTIONS__GET_PRODUCT_DETAILS_VIEW",
    GET_CATEGORIES_VIEW                : "REPORT_ACTIONS__GET_CATEGORIES_VIEW",
    GET_MARKETPLACE_PAGES_VIEWS        : "REPORT_ACTIONS__GET_MARKETPLACE_PAGES_VIEWS",
    GET_PAYOUTS_REPORT                 : "REPORT_ACTIONS__GET_PAYOUTS_REPORT",
    GET_SALES_REPORT                   : "REPORT_ACTIONS__GET_SALES_REPORT",
    GET_REFUND_REPORT                  : "REPORT_ACTIONS__GET_REFUND_REPORT",
    GET_SALES_PERFORMANCE_REPORT       : "REPORT_ACTIONS__GET_SALES_PERFORMANCE_REPORT",
    SORT_SALES_REPORT                  : "REPORT_ACTIONS__SORT_SALES_REPORT",
    SORT_REFUNDS_REPORT                : "REPORT_ACTIONS__SORT_REFUNDS_REPORT",
    PAGINATED_SALES_REPORT             : "REPORT_ACTIONS__PAGINATED_SALES_REPORT",
    PAGINATED_REFUNDS_REPORT           : "REPORT_ACTIONS__PAGINATED_REFUNDS_REPORT",
    PAGINATED_PAYOUTS_REPORT           : "REPORT_ACTIONS__PAGINATED_PAYOUTS_REPORT"
});

export const MESSAGES_ACTIONS= Object.freeze({
    GET_ANNOUNCMENTS                    : "MESSAGES_ACTIONS__GET_ANNOUNCMENTS",
    GET_POLICIES                        : "MESSAGES_ACTIONS__GET_POLICIES",
});

export const API_ERROR = Object.freeze({
    PRODUCT           : "PRODUCT_ERROR",
});

export const LOADING_URL = "LOADING_URL";

export const ADOBE_LAUNCH_ACTIONS= Object.freeze({
    CREATE__MARKETING_ITEM            : "ADOBE_LAUNCH_ACTIONS__CREATE_MARKETING_ITEM",
});
