import React from 'react';

import '../Css/Landing.css';
import Launch_Footer from '../Landing/footer';
import Launch_Header from '../Landing/header';

const Acknowledgement = () => {

    return (
        <div className="ack-container">
            <Launch_Header/>
            <div className="col-12 mb-4 ack_body">
                <div className="mt-4"><h2>Thank you for your submission. A reviewer will get back to you shortly, if there are any questions. </h2></div>
            </div>
            <Launch_Footer/>
        </div>
    );
}
export default Acknowledgement;
