import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import xss from "xss";

import { URL_ADDRESSES } from "../constants/URL_Addresses";
import { PRODUCT_TYPES } from "../constants/AppConstants";


import AuthenticatedRoute from "./AuthenticatedRoute";

import LandingPage from "../../scenes/Landing";
import AdobeLaunchLandingPage from "../../scenes/AdobeLaunch/Landing";
import AdobeLaunchInternalPage from "../../scenes/AdobeLaunch/Internal";
import LoadingIcon from "../../components/LoadingIcon";

import HelpCenter from "../../scenes/HelpCenter";
import Notification from "../../scenes/HelpCenter";

const AccountsAndSettingsPage = lazy(() =>
    import("../../scenes/AccountsAndSettings")
);
const ReportsSalesPage = lazy(() => import("../../scenes/Reports/Sales"));
const ReportsAnalyticsPage = lazy(() =>
    import("../../scenes/Reports/Analytics")
);

const ProductPage = lazy(() => import("../../scenes/Products"));
const Preview = lazy(() => import("../../scenes/Preview"));


export default () => (
    <Suspense fallback={<LoadingIcon loaderWithMinHeight="true"/>}>
        <Switch>
            {/* Sign In */}
            <Route
                exact
                path={URL_ADDRESSES.INTERNAL_HOME_SIGNIN}
                render={({ location }) => {
                    window.location.href = xss(location.pathname);
                }}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_HOME_LOGIN}
                to={URL_ADDRESSES.INTERNAL_HOME_SIGNIN}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_HOME_LOGON}
                to={URL_ADDRESSES.INTERNAL_HOME_SIGNIN}
            />

            {/* Sign Out */}
            <Route
                exact
                path={URL_ADDRESSES.INTERNAL_HOME_SIGNOUT}
                render={({ location }) => {
                    window.location.href = xss(location.pathname);
                }}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_HOME_SIGNOUT}
                to={URL_ADDRESSES.INTERNAL_HOME_SIGNOUT}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_HOME_SIGNOFF}
                to={URL_ADDRESSES.INTERNAL_HOME_SIGNOUT}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_HOME_LOGOUT}
                to={URL_ADDRESSES.INTERNAL_HOME_SIGNOUT}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_HOME_LOGOFF}
                to={URL_ADDRESSES.INTERNAL_HOME_SIGNOUT}
            />

            {/* Landing Page */}
            <AuthenticatedRoute
                exact
                path={URL_ADDRESSES.INTERNAL_HOME}
                component={LandingPage}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_HOME_HOME}
                to={URL_ADDRESSES.INTERNAL_HOME}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_HOME_INDEX}
                to={URL_ADDRESSES.INTERNAL_HOME}
            />

            {/* Accounts and Profile */}
            <AuthenticatedRoute
                path={URL_ADDRESSES.INTERNAL_ACCOUNT}
                component={AccountsAndSettingsPage}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_ACCOUNT_WITH_S}
                to={URL_ADDRESSES.INTERNAL_ACCOUNT}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_ACCOUNT_INFORMATION}
                to={URL_ADDRESSES.INTERNAL_ACCOUNT}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_ACCOUNT_SETTINGS}
                to={URL_ADDRESSES.INTERNAL_ACCOUNT}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_ACCOUNT_SETTINGS_NO_S}
                to={URL_ADDRESSES.INTERNAL_ACCOUNT}
            />

            {/* Extension Page */}
            <AuthenticatedRoute
                path={URL_ADDRESSES.INTERNAL_EXTENSIONS}
                component={() => (
                    <ProductPage productType={PRODUCT_TYPES.EXTENSION} />
                )}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_EXTENSIONS}
                to={URL_ADDRESSES.INTERNAL_EXTENSIONS}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_EXTENSIONS_NO_S}
                to={URL_ADDRESSES.INTERNAL_EXTENSIONS}
            />


            {/* Themes Page */}
            <AuthenticatedRoute
                path={URL_ADDRESSES.INTERNAL_THEMES}
                component={() => (
                    <ProductPage productType={PRODUCT_TYPES.THEME} />
                )}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_THEMES_NO_S}
                to={URL_ADDRESSES.INTERNAL_THEMES}
            />

            {/* Shared Package Page */}
            <AuthenticatedRoute
                path={URL_ADDRESSES.INTERNAL_SHARED_PACKAGES}
                component={() => (
                    <ProductPage productType={PRODUCT_TYPES.SHARED_PACKAGE} />
                )}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_SHARED_PACKAGES}
                to={URL_ADDRESSES.INTERNAL_SHARED_PACKAGES}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_SHARED_PACKAGES_NO_S}
                to={URL_ADDRESSES.INTERNAL_SHARED_PACKAGES}
            />
            <Redirect
                from={URL_ADDRESSES.INTERNAL_SHARED_PACKAGES_SHORT}
                to={URL_ADDRESSES.INTERNAL_SHARED_PACKAGES}
            />

            {/* Reports Page */}
            <AuthenticatedRoute
                exact
                path={URL_ADDRESSES.INTERNAL_REPORTS_SALES}
                component={ReportsSalesPage}
            />
            <AuthenticatedRoute
                exact
                path={URL_ADDRESSES.INTERNAL_REPORTS_ANALYTICS}
                component={ReportsAnalyticsPage}
            />

            {/* Help Center Pages */}
            <AuthenticatedRoute
                exact
                path={URL_ADDRESSES.INTERNAL_HELP_CENTER}
                component={HelpCenter}
            />

            {/* Making preview route unauthenticated  so that it can be accessed from admin */}
            <Route
                exact
                path={URL_ADDRESSES.INTERNAL_PREVIEW_PRODUCT}
                component={Preview}
            />

            {/* 404 Page - will just default to landing for now */}
            <AuthenticatedRoute component={LandingPage} />
            <Redirect to="/" />

            {/* Adobe Launch Metadata Submission */}
            <Route exact path={URL_ADDRESSES.EXTERNAL_ADOBE_LAUNCH} component={AdobeLaunchLandingPage} />

            {/* Adobe Launch Internal Metadata Submission */}
            <Route exact path={URL_ADDRESSES.INTERNAL_ADOBE_LAUNCH} component={AdobeLaunchInternalPage} />

        </Switch>
    </Suspense>
);
