import React, { useState, useEffect, useRef } from 'react';

import '../Css/Landing.css';
import Launch_Footer from '../Landing/footer';
import Launch_Header from '../Landing/header';
import {Form, Button, RadioGroup, TextArea, TextField, Checkbox, Link, Radio} from "@adobe/react-spectrum";
import {URL_ADDRESSES} from "../../../utils/constants/URL_Addresses";
import store, {historyStore} from "../../../utils/store";
import {createMarketingData} from "../../../actions/adobeLaunch";


const InternalEmployeeForm = () => {

        // useRef is to test if it's the first render. If yes, skip validation trigger with the help of needValidation flag
        const firstInternalFormRender = useRef(true);
        let [needValidation, setNeedValidation] = useState(false);
        let [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

        // All required fields
        let [name, setName] = useState('');
        let [orgId, setOrgId] = useState('');
        let [email, setEmail] = useState('');
        let [extName, setExtName] = useState('');
        let [extVersionNo, setExtVersionNo] = useState('');
        let [extPkgId, setExtPkgId] = useState('');
        let [isExtPvt, setIsExtPvt] = useState('');
        let [privateUrl, setPrivateUrl] = useState('');


        let [filledAuthorSection, setFilledAuthorSection] = useState('');
        let [extRelNotes, setExtRelNotes] = useState('');
        let [publishedDocUrl, setPublishedDocUrl] = useState('');
        let [publishedRelNotesUrl, setPublishedRelNotesUrl] = useState('');
        let [backwardCompatible, setBackwardCompatible] = useState('');
        let [gitRepo, setGitRepo] = useState('');
        let [gitTagMatchingVersion, setGitTagMatchingVersion] = useState('');
        let [gitTagLastVersion, setGitTagLastVersion] = useState('');

        //All optional fields
        let [exUrlIncluded, setExUrlIncluded] = useState('');
        let [publishedExUrl, setPublishedExUrl] = useState('');
        let [projectSponsor, setProjectSponsor] = useState('');


        useEffect (() => {
            if (firstInternalFormRender.current) {
                firstInternalFormRender.current = false;
                return;
            }
            setNeedValidation(true);
            enableInternalSubmitButton();
        });

        const enableInternalSubmitButton = () => {
            if (name && orgId && email && extName && extVersionNo && extPkgId && isExtPvt && filledAuthorSection && extRelNotes && publishedDocUrl && publishedRelNotesUrl && backwardCompatible && gitRepo && gitTagMatchingVersion && gitTagLastVersion ) {
                setIsSubmitDisabled(false);
            } else {
                setIsSubmitDisabled(true);
            }
        };

        const sanitizeRadio = (val) =>{
            return (val === "yes")
        };

        const handleSubmit = () => {
            // sanitizing the radio inputs
            let s_isExtPvt = sanitizeRadio(isExtPvt);
            let s_backwardCompatible= sanitizeRadio(backwardCompatible);
            let s_hasFilledAuthor= sanitizeRadio(filledAuthorSection);
            let s_hasExUrlIncluded= sanitizeRadio(exUrlIncluded);

            let extdata = {
                "data": {
                    "id": extPkgId,
                    "version": extVersionNo,
                    "display_name": extName,
                    "exchange_url": publishedExUrl,
                    "archive_url": gitRepo,
                    "private_url": privateUrl,
                    "git_tag_to_be_published": gitTagMatchingVersion,
                    "git_tag_last_published": gitTagLastVersion,
                    "documentation_url": publishedDocUrl,
                    "release_notes_url": publishedRelNotesUrl,
                    "release_notes": extRelNotes,

                    "has_released_as_private": s_isExtPvt,
                    "has_released_source_code": true,
                    "has_certified_backward_compatibility": s_backwardCompatible,
                    "has_filled_exchange_url_in_json": s_hasExUrlIncluded,
                    "has_filled_author_in_json": s_hasFilledAuthor,

                    "owner": {
                        "org_id": orgId,
                        "company_name": ""
                    },

                    "developer": {
                        "name": name,
                        "email": email,
                        "sponsor": projectSponsor
                    },
                },
            };
            store.dispatch(createMarketingData(extdata,extPkgId));
            historyStore.push(URL_ADDRESSES.ADOBE_LAUNCH_ACKNOWLEDGEMENT);
        };

        return (
            <div>
                <Launch_Header/>
                <div className="col-12 mb-4">

                    <div className="mt-2"><h1>Developer Info</h1></div>
                    <Form isRequired necessityIndicator="label" id="launch-internal-form">
                        <div>
                            { needValidation ?
                                <div className="mt-2">
                                    <TextField label="Your Name" width="size-3600" onChange={setName} value={name} validationState={name ? "valid" : "invalid"}/>
                                </div>
                                :
                                <div className="mt-2">
                                    <TextField label="Your Name" width="size-3600" onChange={setName} value={name}/>
                                </div>
                            }

                            <div className="mt-2">
                                {needValidation ?
                                    <TextField label="Experience Cloud Org ID" onChange={setOrgId} value={orgId} validationState={orgId ? "valid" : "invalid"}
                                               width="size-3600"/>
                                    :
                                    <TextField label="Experience Cloud Org ID" onChange={setOrgId} value={orgId}
                                               width="size-3600"/>
                                }
                            </div>

                            <div className="mt-2">
                                { needValidation ?
                                    <TextField label="Your Email Address" width="size-3600" onChange={setEmail} value={email} validationState={email ? "valid" : "invalid"}/>
                                    :
                                    <TextField label="Your Email Address" width="size-3600" onChange={setEmail} value={email}/>
                                }
                            </div>

                            <div className="mt-2"><h1>Extension Info</h1></div>

                            { needValidation ?
                                <div className="mt-2">
                                    <TextField label="Extension Name" onChange={setExtName} value={extName} validationState={extName ? "valid" : "invalid"} width="size-3600"/>
                                </div>
                                :
                                <div className="mt-2">
                                    <TextField label="Extension Name" onChange={setExtName} value={extName} width="size-3600"/>
                                </div>
                            }

                            { needValidation ?
                                <div className="mt-2">
                                    <TextField label="Extension Version Number" onChange={setExtVersionNo} value={extVersionNo} validationState={extVersionNo ? "valid" : "invalid"} width="size-3600"/>
                                </div>
                                :
                                <div className="mt-2">
                                    <TextField label="Extension Version Number" onChange={setExtVersionNo} value={extVersionNo} width="size-3600"/>
                                </div>
                            }

                            <div className="mt-2">
                                { needValidation ?
                                    <TextField label="extension_package ID" onChange={setExtPkgId} value={extPkgId} validationState={extPkgId ? "valid" : "invalid"} width="size-3600"/>
                                :
                                    <TextField label="extension_package ID" onChange={setExtPkgId} value={extPkgId} width="size-3600"/>
                                }
                            </div>

                            <div className="mt-2">
                                { needValidation ?
                                    <RadioGroup label="I have released my extension as a Private extension (Required, you must do a Private Release before Adobe can do a Public Release)" value={isExtPvt}  onChange={setIsExtPvt} validationState={isExtPvt ? "valid" : "invalid"}>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </RadioGroup>
                                    :
                                    <RadioGroup label="I have released my extension as a Private extension (Required, you must do a Private Release before Adobe can do a Public Release)" value={isExtPvt}  onChange={setIsExtPvt}>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </RadioGroup>
                                }
                            </div>

                            <div className="mt-2">
                                { needValidation ?
                                    <TextField label='URL for Launch Property with this extension installed as "Private"' onChange={setPrivateUrl} value={privateUrl} validationState={privateUrl ? "valid" : "invalid"}
                                               width="size-3600"/>
                                    :
                                    <TextField label='URL for Launch Property with this extension installed as "Private"' onChange={setPrivateUrl} value={privateUrl}
                                               width="size-3600"/>
                                }
                            </div>

                            <div className="mt-2">
                                { needValidation ?
                                    <RadioGroup
                                        label="I have filled out the author section of extension.json with name and email (preferably email alias for a group)"
                                        value={filledAuthorSection} onChange={setFilledAuthorSection} validationState={filledAuthorSection ? "valid" : "invalid"}>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </RadioGroup>
                                    :
                                    <RadioGroup
                                        label="I have filled out the author section of extension.json with name and email (preferably email alias for a group)"
                                        value={filledAuthorSection} onChange={setFilledAuthorSection}>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </RadioGroup>
                                }
                            </div>

                            <div className="mt-2">
                                { needValidation ?
                                    <TextArea width="size-3600"
                                              label="Please provide a few bullets about the functionality that this extension provides, either about the initial release or the differences between this and the previous version" value={extRelNotes} onChange={setExtRelNotes} validationState={extRelNotes ? "valid" : "invalid"}/>
                                    :
                                    <TextArea width="size-3600"
                                              label="Please provide a few bullets about the functionality that this extension provides, either about the initial release or the differences between this and the previous version" value={extRelNotes} onChange={setExtRelNotes} />
                                }
                            </div>

                            <div className="mt-2"><h1>Documentation</h1></div>

                            <div>Adobe developers should make sure that their extensions provide documentation.  This should describe the extension's functionality and how to use it.</div>

                            <div>Please make a page for your extension documentation here: <Link><a href="https://docs.adobe.com/content/help/en/launch/using/extensions-ref/adobe-extension/overview.html" target="_blank"> https://docs.adobe.com/content/help/en/launch/using/extensions-ref/adobe-extension/overview.html </a></Link></div>

                            <div>These docs are controlled by a Github repo at <Link><a href="https://github.com/AdobeDocs/launch.en" target="_blank"> https://github.com/AdobeDocs/launch.en</a></Link>, please fork the repo, make your updates, and submit PRs.</div>

                            <div>
                                If you prefer to have your docs in another place, then please make an edit to our repo and provide a link to your own docs.
                            </div>

                            <div> Documentation is included in the extension validation process.  Your extension will not be made public without appropriate documentation.</div>

                            <div className="mt-2">
                                { needValidation ?
                                    <TextField
                                        label="URL for Published Docs (or to the PR on our Repo that we should review/publish)" value={publishedDocUrl} onChange={setPublishedDocUrl} validationState={publishedDocUrl ? "valid" : "invalid"}
                                        width="size-3600"/>
                                    :
                                    <TextField
                                        label="URL for Published Docs (or to the PR on our Repo that we should review/publish)" value={publishedDocUrl} onChange={setPublishedDocUrl}
                                        width="size-3600"/>
                                }
                            </div>

                            <div className="mt-2"><h1>Release Notes</h1></div>

                            <div>Adobe Developers should provide release notes for each new version of their extension that describes what is changing in this latest version.  In the future, we hope to integrate this more seamlessly into the extension package and manifest, but in the meantime, please provide release notes on your own.</div>

                            <div>The simplest method would be to publish release notes alongside your extension documentation.  You can do this within our repo and product docs (see Documentation immediately above) if you do not have another place to publish them.</div>

                            <div>Release Notes are now included in the validation process.  Your extension will not be published without release notes.</div>

                            <div className="mt-2">
                                { needValidation ?
                                    <TextField
                                        label="URL for Published Release Notes (or to the PR on our Repo that we should merge)" value={publishedRelNotesUrl} onChange={setPublishedRelNotesUrl} validationState={publishedRelNotesUrl ? "valid" : "invalid"}
                                        width="size-3600"/>
                                    :
                                    <TextField
                                        label="URL for Published Release Notes (or to the PR on our Repo that we should merge)" value={publishedRelNotesUrl} onChange={setPublishedRelNotesUrl}
                                        width="size-3600"/>

                                }
                            </div>

                            <div className="mt-2"><h1>Backward Compatibility</h1></div>

                            <div>Launch has published a backward compatibility standard to ensure that extension upgrades to not break anything for existing extension users.  That standard can be found here:<Link><a href="https://developer.adobelaunch.com/extensions/reference/backwards-compatibility/" target="_blank"> https://developer.adobelaunch.com/extensions/reference/backwards-compatibility/</a></Link></div>

                            <div>When you make changes to your extension, certain changes are allowed and others are forbidden.</div>
                            <div>Allowed Changes - You may add new components (event types, condition types, etc), add new optional fields to settings objects, and change required fields to optional ones.</div>
                            <div>Forbidden changes - You may not remove a component (event types, condition types, etc), remove fields from components, change optional fields to required fields, add new required fields, or change the API of any shared modules which your extension provides.</div>

                            <div>If you want to make forbidden changes, you can't, but the Backwards Compatibility Standard linked above calls out some options for you to consider.</div>

                            <div className="mt-2">
                                { needValidation ?
                                    <RadioGroup
                                        label="I hereby certify on pain of death that I have not made any of the forbidden changes to my Launch extension with this update."
                                        value={backwardCompatible} onChange={setBackwardCompatible}
                                        validationState={backwardCompatible ? "valid" : "invalid"}>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </RadioGroup>
                                    :
                                    <RadioGroup
                                        label="I hereby certify on pain of death that I have not made any of the forbidden changes to my Launch extension with this update."
                                        value={backwardCompatible} onChange={setBackwardCompatible}>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </RadioGroup>
                                }
                            </div>

                            <div className="mt-2"><h1>Git Info</h1></div>

                            <div className="mt-2">
                                { needValidation ?
                                    <TextField label="Git Repo" value={gitRepo} onChange={setGitRepo} validationState={gitRepo ? "valid" : "invalid"} width="size-3600"/>
                                    :
                                    <TextField label="Git Repo" value={gitRepo} onChange={setGitRepo} width="size-3600"/>
                                }
                            </div>

                            <div className="mt-2">
                                { needValidation ?
                                    <TextField label="Git Tag (matching version to be published)" value={gitTagMatchingVersion} onChange={setGitTagMatchingVersion} validationState={gitTagMatchingVersion ? "valid" : "invalid"} width="size-3600"/>
                                    :
                                    <TextField label="Git Tag (matching version to be published)" value={gitTagMatchingVersion} onChange={setGitTagMatchingVersion} width="size-3600"/>
                                }
                            </div>

                            <div className="mt-2">
                                {needValidation ?
                                    <TextField label="Git Tag (matching last published version)" value={gitTagLastVersion} onChange={setGitTagLastVersion} validationState={gitTagLastVersion ? "valid" : "invalid"} width="size-3600"/>
                                    :
                                    <TextField label="Git Tag (matching last published version)" value={gitTagLastVersion} onChange={setGitTagLastVersion} width="size-3600"/>
                                }
                            </div>

                            <div className="mt-2"><h1>First Time Submissions Only</h1></div>

                            <div className="mt-2">
                                <TextField width="size-3600" label="Published Exchange Listing URL " isRequired={false} value={publishedExUrl} onChange={setPublishedExUrl}/>
                            </div>

                            <div className="mt-2">
                                <Checkbox value={exUrlIncluded} onChange={setExUrlIncluded} isRequired={false} >I have included the above URL in the "exchangeUrl" field in my Extension Manifest (extension.json file)</Checkbox>
                            </div>

                            <div className="mt-2">
                                <TextField width="size-3600" label="Project Sponsor (person or team) " isRequired={false} value={projectSponsor} onChange={setProjectSponsor}/>
                            </div>

                            <div className="mt-2">
                                <Button variant="cta" id="btnInternalSubmit" isDisabled={isSubmitDisabled} onPress={handleSubmit} >Finish</Button>
                            </div>
                        </div>
                    </Form>
                </div>
                <Launch_Footer/>
            </div>
        );
}
export default InternalEmployeeForm;
