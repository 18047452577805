import { MESSAGES_ACTIONS } from '../utils/constants/ActionTypes';


import {
    getMessagePoliciesURL,
    getMessageAnnouncementsURL    
} from '../utils/urls';


import loadingURL from './url';
import { FETCH_GET_OPTIONS } from '../utils/constants/RestHelpers';
import api from '../utils/apiFetch';

/* ----- Base Actions ----- */

const messagePoliciesAction = (policies) => {
    return {
        type    : MESSAGES_ACTIONS.GET_POLICIES,
        payload : policies,
    };
}

const messageAnnouncmentsAction = (announcements) => {
    return {
        type    : MESSAGES_ACTIONS.GET_ANNOUNCMENTS,
        payload : announcements,
    };
}

export const getMessagePolicies = () => (dispatch) => {
    const url = getMessagePoliciesURL();
    return api(url, {...FETCH_GET_OPTIONS}, dispatch)
        .then((policies)=>{
            dispatch(loadingURL(url, 'GET', 'success'));
            return dispatch(messagePoliciesAction(policies));
        })
        .catch(()=>{})
}

export const getMessageAnnouncements = () => (dispatch) => {
    const url = getMessageAnnouncementsURL();
    return api(url, {...FETCH_GET_OPTIONS}, dispatch)
        .then((announcements)=>{
            dispatch(loadingURL(url, 'GET', 'success'));
            return dispatch(messageAnnouncmentsAction(announcements));
        })
        .catch(()=>{})
}