// Notification , user feedback kind of messages //



export const MESSAGES_NOTIFICATION = Object.freeze({
    TECHNICAL_NEW_NOTIFICATION      : "Test your code package thoroughly to help reduce review time.",
    DRAFT_SAVED_NOTIFICATION        : "Draft has been saved.",
    TECHNICAL_COMPLETE_NOTIFICATION : "Your technical submission is complete! Estimated time until review is approximately 10 business days based on our current queue."
});

export const PLACE_HOLDER_TEXTS = Object.freeze({
    TEXT_AREA : "Chia whatever messenger bag skateboard, nulla next level cray tilde taxidermy slow-carb irure hashtag. Vero anim copper mug intelligentsia nisi culpa est, irure laborum craft beer nesciunt. Qui minim hexagon, ramps laboris anim snackwave man braid banh mi ea franzen synth you probably haven't heard of them shoreditch.",
});

export const DISABLED_BUTTON_MESG = Object.freeze({
    DISABLED_SUBMIT : "All sections under marketing submission needs to be completed and saved before the button is enabled."
});