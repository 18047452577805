

import { FULFILLED } from 'redux-promise-middleware';
import { PRODUCT_ACTIONS } from '../utils/constants/ActionTypes';

export default (state = [], action) => {
    switch (action.type) {
        case PRODUCT_ACTIONS.GET_PRODUCT_CATEGORIES : 
        case PRODUCT_ACTIONS.GET_PRODUCT_CATEGORIES + "_" + FULFILLED : {
            //TODO//probably better to organize this data to something more usefull for the front end, will do that after updating UI//JRP//
            state = action.payload;

            return state;
        }
        default : {
            return state
        }
    }

    
};