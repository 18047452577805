import { FULFILLED, PENDING } from 'redux-promise-middleware';
import { PRODUCT_ACTIONS } from '../utils/constants/ActionTypes';
import { MERGE_ARRAY_OF_OBJECTS_REMOVE_DUPLICATES } from '../utils/helpers/utilityHelpers';

const initialState = {
    selectedProduct : {},
    products        : []
};

/*
    API is setup to return a http status of `200` on an error/failure, but it includes a `code` status.  We should check
    if a code exists. If a code does exist, then there was an error with the call, so we should not update reducer
    data (which would trigger a redraw and prop updates) because if there was an error, then the proper data structure
    is not returned. and thus will cause the app to crash due to accessing properties that do not exist.

    //TODO//discuss with team best way to handle these "codes" either in the reducer or on the service calls?//JRP//
 */
//grab `code` out of argument data, set default to `200` if it doesnt exist,  if it exists, and is something other than 200, then return true
const hasAPIErrorCode = ({code = 200}) => (code !== 200);


export default (state = initialState, action) => {
    //always set the default object structure for the products reducer, even if an empty object is passed in.
    state = !Object.keys(state).length ? initialState:state;

    switch (action.type) {
        case PRODUCT_ACTIONS.GET_USER_PRODUCT_LIST + "_" + PENDING : {
            break;
        }
        case PRODUCT_ACTIONS.GET_USER_PRODUCT_LIST:
        case PRODUCT_ACTIONS.GET_USER_PRODUCT_LIST + "_" + FULFILLED : {
            state = {...state, products:action.payload !== undefined ? action.payload : []};
            break;
        }
        case PRODUCT_ACTIONS.GET_USER_PRODUCT_LIST_BY_URL_KEY + "_" + PENDING : {
        
            state = {...state, products: state.products.filter((product => product.url_key === action.meta.url_key))};
            break;
        }
        case PRODUCT_ACTIONS.GET_USER_PRODUCT_LIST_BY_URL_KEY:
        case PRODUCT_ACTIONS.GET_USER_PRODUCT_LIST_BY_URL_KEY + "_" + FULFILLED : {
            const updatedProducts = MERGE_ARRAY_OF_OBJECTS_REMOVE_DUPLICATES(state.products, action.payload, 'submission_id');
            state = {...state, products:updatedProducts};
            break;
        }
        case PRODUCT_ACTIONS.GET_USER_PRODUCT_ITEM_BY_ID_FOR_SP + "_" + FULFILLED : {
            state = {...state, selectedProduct:(action.payload !== undefined ? {...action.payload} : {})};
            break;
        }
        case PRODUCT_ACTIONS.GET_USER_PRODUCT_ITEM_BY_ID + "_" + FULFILLED : {
            state = {...state,
                selectedProduct:(action.payload !== undefined ? {...action.payload} : {}),
                products : action.payload !== undefined
                 ? (
                    ((state.products.length && state.products.find((product)=>product.submission_id === action.payload.submission_id)))
                    ? state.products.map(product => product.submission_id === action.payload.submission_id ? action.payload : product)
                    : [...state.products, action.payload]
                ) : []
            };
            break;
        }
        case PRODUCT_ACTIONS.CREATE_USER_PRODUCT_ITEM :
        case PRODUCT_ACTIONS.CREATE_USER_PRODUCT_ITEM + "_" + FULFILLED : {
                state = {
                    ...state,
                    selectedProduct: (action.payload !== undefined ? {...action.payload[0]} : {}),
                };

                state.products.push({...action.payload[0]});
            break;
        }
        case PRODUCT_ACTIONS.UPDATE_USER_PRODUCT_ITEM : {
            const payloadProduct = !Array.isArray(action.payload) ? [action.payload] : action.payload;
            state = {
                ...state,
                selectedProduct: (action.payload !== undefined ? {...payloadProduct[0]} : {}),
                products: [...state.products.filter(product => product.submission_id !== payloadProduct[0].submission_id), payloadProduct[0]]
            };
            break;
        }
        case PRODUCT_ACTIONS.UPDATE_USER_PRODUCT_ITEM + "_" + FULFILLED : {
            if (action.payload) {
                if (!hasAPIErrorCode(action.payload[0])) {
                    state = {
                        ...state,
                        selectedProduct: (action.payload !== undefined ? {...action.payload[0]} : {}),
                        products: action.payload !== undefined ? (state.products.map(product => product.submission_id === action.payload[0].submission_id ? action.payload[0] : product)) : []
                    };
                }
            }
            break;
        }
        case PRODUCT_ACTIONS.SORT_USER_PRODUCT_VERSIONS + "_" + FULFILLED : {
            state = {...state, products:action.payload !== undefined ? action.payload : []};
            break;
        }
        case PRODUCT_ACTIONS.GET_USER_PRODUCT_PREVIEW + "_" + FULFILLED : {
            state = {...state,
                selectedProduct:(action.payload !== undefined ? {...action.payload} : {})
            };
            break;
        }
        case PRODUCT_ACTIONS.GET_USER_PRODUCT_PREVIEW_FOR_ADMIN + "_" + FULFILLED : {
            state = {...state,
                selectedProduct:(action.payload !== undefined ? {...action.payload} : {})
            };
            break;
        }
        default : {
            return state
        }
    }

    return state;
};