import React, { Component } from "react";
//import { hotjar } from "react-hotjar";

import AppRoutes from "../utils/Routes";

import "./App.css";

/**
 * Main Application file for the DevPortal SPA UI.  `App.js` renders the `Routing` component
 */
class App extends Component {
    render() {
        //92079 is the hotjar id used on developer.magento.com
        //6 is the software version of hotjar we are using.
        //hotjar.initialize(920079, 6);

        return (
            <div className="container bg-white">
                <AppRoutes />
            </div>
        );
    }
}

export default App;
