import { FULFILLED } from 'redux-promise-middleware';

import { USER_ACCESS_KEYS_ACTIONS } from '../utils/constants/ActionTypes';



const initialState = {"m2": [], "m1": []};

const accessKeysReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_ACCESS_KEYS_ACTIONS.GET_USER_ACCESS_KEYS + "_" + FULFILLED : {
            state = {...state, ...action.payload};
            break;
        }
        case USER_ACCESS_KEYS_ACTIONS.CREATE_USER_ACCESS_KEY + "_" + FULFILLED : {
            let newM2AccessKeysList = action.payload.m2;
            let newState = {...state};

            for (let i=0; i < newM2AccessKeysList.length; i++) {
                let _currentNewM2AccessKey = newM2AccessKeysList[i];

                if ((_currentNewM2AccessKey.code === 200) &&
                     _currentNewM2AccessKey.is_enabled) {   //todo//remove 'is_enabled' check; this is temporary waiting for repo "unique" name enforcement//JRP//
                    newState.m2.push(_currentNewM2AccessKey);
                }
            }

            state = {...state, m2:newState.m2};
            break;
        }
        case USER_ACCESS_KEYS_ACTIONS.UPDATE_USER_ACCESS_KEY + "_" + FULFILLED : {
            let updatedM2AccessKeysList = action.payload.m2;
            let existingState = {...state};

            //TODO//I really dont like the double loop here, but it will have to for now//maybe refactor in the future?//JRP//
            for (let i=0; i < existingState.m2.length; i++) {
                let _currentExistingM2AccessKey = existingState.m2[i];

                //need to loop through updated list and update existing state
                for (let n=0; n < updatedM2AccessKeysList.length; n++) {
                    let _currentNewM2AccessKey = updatedM2AccessKeysList[n];

                    //TODO////server really only checks against the name (as its supposed to be unique), so maybe only check on that?//JRP//
                    if ((_currentExistingM2AccessKey.label === _currentNewM2AccessKey.label) &&
                        (_currentExistingM2AccessKey.user_key === _currentNewM2AccessKey.user_key) &&
                        (_currentExistingM2AccessKey.password_key === _currentNewM2AccessKey.password_key)) {

                        _currentExistingM2AccessKey.is_enabled = _currentNewM2AccessKey.is_enabled
                    }
                }
            }

            state = {...state, ...existingState};
            break;
        }
        case USER_ACCESS_KEYS_ACTIONS.DELETE_USER_ACCESS_KEY + "_" + FULFILLED : {
            let existingState = {...state};
            let newState = {m2:[]};

            for (let i=0; i<existingState.m2.length; i++) {
                if (existingState.m2[i].label !== action.payload) {
                    newState.m2.push(existingState.m2[i]);
                }
            }

            state = {...state, ...newState};
            break;
        }
        default : {
            return state;
        }
    }

    return state;
};

export default accessKeysReducer;