export const FETCH_API = "FETCH_API";

export const FETCH_GET_OPTIONS = Object.freeze({
    credentials : "same-origin",
    headers     : { 'Content-Type': 'application/json' },
    method      : 'GET'
});

export const FETCH_POST_OPTIONS = Object.freeze({
    credentials : "same-origin",
    headers     : { 'Content-Type': 'application/json' },
    method      : 'POST'
});

export const FETCH_POST_UPLOAD_OPTIONS = Object.freeze({
    credentials : "same-origin",
    headers     : {'Accept': 'text/plain', 'Content-Type': 'text/plain', 'enctype':'text/plain'},
    method      : 'POST'
});

export const FETCH_PUT_OPTIONS = Object.freeze({
    credentials : "same-origin",
    headers     : { 'Content-Type': 'application/json' },
    method      : 'PUT'
});

/**
 * we're not using patch as of yet...
 * Also, make sure the `PATCH` method name is uppercase'd, as its not automatically normalized like the others...
 */
/*
export const FETCH_PATCH_OPTIONS = Object.freeze({
    credentials : "same-origin",
    headers     : { 'Content-Type': 'application/json' },
    method      : 'PATCH'
});
*/

export const FETCH_DELETE_OPTIONS = Object.freeze({
    credentials : "same-origin",
    headers     : { 'Content-Type': 'application/json' },
    method      : 'DELETE'
});