import {URL_ADDRESSES} from "../../../utils/constants/URL_Addresses";
import adobe_logo from "../../../assets/images/magento/Adobe_Corporate_Horizontal_Lockup_White_RGB.svg";
import React from "react";

const LaunchFooter = () => {
        return (
            <footer className="container bg-dark">
                <div className="d-flex flex-wrap py-4 px-2 justify-content-between">
                    <div className="align-self-center">
                        <ul className="list-unstyled list-inline m-0">
                            <li className="list-inline-item">
                                <a target="_blank" href={URL_ADDRESSES.EXTERNAL_MAGENTO_HOME}>
                                    <img src={adobe_logo} className="magento-logo" alt="Adobe"/>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </footer>
        )
};

export default LaunchFooter;
