import React from "react";
import { array, string } from "prop-types";
import NotificationList from "./notificationList";

const NotificationTab = (props) => {
    const {notifications, idName, header} = props;
    return (
        <div className="row">
            <div className="col-sm-12 mt-4">
                <h1 className="magento-h3 text-dark">{header}</h1>
                <NotificationList
                    notifications={notifications}
                    idName={idName}
                />
            </div>
        </div>
    );
};

NotificationTab.propTyps = {
    notifications: array.isRequired,
    idName: string.isRequired,
    header: string.isRequired,
};

export default NotificationTab;
