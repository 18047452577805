import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import { routerReducer } from 'react-router-redux';
import errorReducer from './error';
import userReducer from './user';
import accessKeysReducer from './accessKeys';
import apiKeysReducer from './apiKeys';
import productReducer from './products';
import notificationReducer from './notification';
import navigationReducer from './navigation';
import servicesLoadingReducer from "./servicesLoadingState";
import reportReducer from "./report";
import productVersionReducer from "./productVersionCompatibilities";
import productCategories from "./productCategories";
import productTestReportsReducer from './productTestReports';
import filesReducer from './files';
import versionCompatibilitiesReducer from './versionCompatibilities';
import messagesReducer from './messages';
import envReducer from './env';

import app from './app';


export default combineReducers({
    errors                        : errorReducer,
    user                          : userReducer,
    env                           : envReducer,
    accessKeys                    : accessKeysReducer,
    apiKeys                       : apiKeysReducer,
    products                      : productReducer,
    versionCompatibilities        : versionCompatibilitiesReducer,
    notification                  : notificationReducer,
    messages                      : messagesReducer,
    servicesLoading               : servicesLoadingReducer,
    report                        : reportReducer,
    productVersionCompatibilities : productVersionReducer,
    productCategories             : productCategories,
    filesLoading                  : filesReducer,
    app                           : app,

    router       : routerReducer,
    navigation   : navigationReducer,
    form: formReducer,
    productTestReports : productTestReportsReducer
});
