export const VERSION_NUMBER_INVALID = "Version is not valid. Allowed format is X.Y.Z  where X,Y,Z are integers having maximum length of 15 chars.";

export const VERSION_NUMBER_EXISTS = "Duplicate version number for this product, please enter in a new version number.";
export const PRODUCT_NAME_EXISTS = "Duplicate name for this product, please enter in a unique name.";

// export const INVALID_USE_OF_MAGENTO_IN_PRODUCT_NAME = "The word 'Magento' is forbidden in the name of the product";

export const INVALID_TERM_IN_PRODUCT_NAME =(terms=[]) =>{
    // Add Quotes to each element.
    const termsWithQuotes = terms.map((term)=>`'${term}'`);

    if( terms.length > 1 ) {
        const listBeforeEnd = termsWithQuotes.slice(0,termsWithQuotes.length-1); // get all but the last element
        const listEnd = termsWithQuotes.slice(termsWithQuotes.length - 1); // get the last element
        const termList = listBeforeEnd.join(", ") + " and " + listEnd.toString(); // add commas to the list except for the last.
        return `The words ${termList} are forbidden`;
    }
    return `The word ${termsWithQuotes.toString()} is forbidden`;
};


export const FILE_UPLOAD_STATUS = Object.freeze({
    MALWARE_DETECTED          : "Error: Encrypted file or Malware Detected",
    INVALID_FILE_SIZE         : "File Size Invalid",
    INVALID_FILE_TYPE         : "File Type Invalid",
    INVALID_FILE_NAME_LENGTH  : "File Name too long",
    FILE_UPLOADING            : "Uploading...",
    MALWARE_CHECK_IN_PROGRESS : "Scanning for Malware",
    MALWARE_ERROR             : "An error has occurred. Please re-upload the file"
});

export const FILE_UPLOAD_ERROR_MESSAGES = {
    MAX_IMAGES: (maxFileCount = 15) => `Maximum images is ${maxFileCount}`,
    IMAGE_NAME_LENGTH: "Image file name is too long",
    IMAGE_DIMENSIONS_TOO_LARGE: (width = 5000, height = 5000) => `Image Dimensions should be ${width}px by ${height}px or less`,
    IMAGE_DIMENSIONS: (width = 250, height = 250) => `Image Dimensions should be ${width}px by ${height}px`,
    IMAGE_FILE_SIZE: "Image file size is too large",
};

export const URL_ERROR_MESSAGES = Object.freeze({
    UNAUTHORIZED_USER : "User is not Authorized to Login"
});

export const INVALID_ACCESS_KEY_NAME = "Access Key name cannot be blank and must be unique";

export const INVALID_API_KEY_NAME = "API Key name cannot be blank and must be unique";
