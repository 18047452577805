import { errorNotification, clearErrorNotification } from "../actions/notifications";
import { push } from "react-router-redux";
import { URL_ADDRESSES } from "../utils/constants/URL_Addresses";

const api = (url, options, dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(clearErrorNotification());
        return fetch(url, options)
          .then(response => {
            // TODO look into using PARSE_JSON_AND_CHECK_STATUS_CODE for the response validation
            if (response.ok) {
                return resolve(response.json());
            } else {
                
                if(response.status >= 400 && response.status < 500 ) {
                    
                    return response.json().then((data)=>{
                        // Check if there is a missing token  'code 1101 message "Missing UST token"'
                        if(data.code === 1101) {
                            dispatch(push(URL_ADDRESSES.INTERNAL_HOME_LOGIN));
                        }
                        return reject(data);
                    })  
                }
                else if(response.status >= 500 && response.status < 600 ) {
                    dispatch(errorNotification(response.statusText))
                    return reject(response);
                }            
            }
          }, error => {
              dispatch(errorNotification(error.statusText))
              return reject(error);
          });
    });
};

export default api;
