
import React from "react";
import adobe_logo from "../../../assets/images/magento/Adobe_Corporate_Horizontal_Lockup_Red_RGB.svg";

const LaunchHeader = () => {
    return (
        <div className="container">
            <div className="d-flex flex-wrap py-4 px-2 justify-content-between">
                <div className="align-self-center">
                    <ul className="list-unstyled list-inline m-0">
                        <li className="list-inline-item launch-logo">
                            <a target="_blank" href="https://www.adobe.com/">
                                <img src={adobe_logo}  alt="Adobe"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default LaunchHeader;
