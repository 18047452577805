import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from "@react-spectrum/button";

import { Link as RouterLink } from 'react-router-dom';
import { Link } from "@react-spectrum/link";

import { URL_ADDRESSES } from "../../../../utils/constants/URL_Addresses";

//images
import profile_default_avatar_small from '../../../../assets/images/profile_default_avatar_small.svg';

import './ContextMenu.css';



class ContextMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showMenu : false
        }
    }

    handleToggleMenu = () => {
        this.setState({showMenu:!this.state.showMenu});
    };

    handleCloseMenu = () => {
        this.setState({showMenu:false});
    };

    handleOnBlur = (event) => {
        let currentTarget = event.currentTarget;

        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                this.handleCloseMenu();
            }
        }, 0);
    };

    render() {
        const openMenuContainer = () => {
            return <div className="menu-content-container">
                    <Link>
                        <RouterLink id="nav_menu_link_account_information"
                              to={URL_ADDRESSES.INTERNAL_ACCOUNT_INFORMATION + URL_ADDRESSES.INTERNAL_INFORMATION}
                              onClick={this.handleCloseMenu}>Account Information</RouterLink>
                    </Link>
                    <Button id="nav_menu_link_sign_out"  variant="primary" isQuiet onPress={this.props.handleLogout}>Sign Out</Button>
            </div>
        };

        return <div className="context-menu" onBlur={this.handleOnBlur}>
                <div className="menu-name">
                    <img src={profile_default_avatar_small} alt="default avatar"/>
                    <Button  id="nav_menu_open" variant="primary" isQuiet onPress={this.handleToggleMenu}>
                        {this.props.userData.first_name + " " + this.props.userData.last_name}
                    </Button>
                </div>
                {this.state.showMenu ? openMenuContainer() : ""}
            </div>
    }
}

ContextMenu.propTypes = {
    userData     : PropTypes.object.isRequired,
    handleLogout : PropTypes.func.isRequired
};

export default ContextMenu;
