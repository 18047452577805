/**
 * RegEx breaks down like so:
 *      /              search within string
 *       (?:           begin the non-capturing grouping
 *        \.           search for a period (`.`)
 *         (           begin the capturing group (part that grabs the actual extension)
 *           [^.]+     search for anything that is not a period (`.`), repeat this search
 *         )           end the capturing group
 *       )?            end the non-capturing group
 *      $              end of line anchor (basically start search from the end of the line
 *
 * @returns Regular Expression statement that isolates the string after the last period (`.`) in a given string
 * @note if there is no period (`.`) in the given string this will return `undefined`
 */
export const FIND_FILE_EXTENSION = (/(?:\.([^.]+))?$/);



/**
 * RegEx breaks down like so:
 *
 *      /                               search within a string
 *      ((http|https?)                  optional `http` or `https` or nothing
 *          :\/\/)?                     if above ^^^ then also an optional `//`
 *              (www\.)?                optional `www.` and allow for sub-domains
 *                  [a-z0-9\-.]         search for a combination of letters, numbers, dashes, or dots
 *                      {1,}\.          include an extension at least 1 characters long
 *                          [a-z]{1,}   include a sub extension at least 1 characters long
 *                              (\/.*$) allows for an optional trailing forward slash
 *      $/i                             end of line anchor and make case-insensitive
 *
 *
 * @returns RegEx statement that will validate a string is in a proper URL structure.
 * @note URL is being validated for company URLs and top level URLs, this wont catch `localhost` url.
 */
export const VALIDATE_URL_STRUCTURE = (/((http|https?):\/\/)?(www\.)?[a-z0-9\-.]{1,}\.[a-z]{1,}((\/.*$)|$)/i);



/**
 * RegEx breaks down like so:
 *
 *      /       search within a string
 *      \s      search for a space, tab, return, or new line
 *      /g      search globally
 *
 * @returns {RegExp} RegEx statement that will find all spaces in a string
 */
export const FIND_ALL_SPACES_IN_STRING = (/\s/g);



/**
 * RegEx breaks down like so:
 *
 *      /       search within a string
 *      [ \t]   search for a space and tabs
 *           +  quantifier that matches between one and unlimited times
 *      $/                  ensure position is at end of the string
 *
 * @returns {RegExp} RegEx statement that will find spaces and tabs to the right in a string
 */
export const FIND_SPACES_TO_THE_RIGHT = (/[ \t]+$/);


/**
 * RegEx breaks down like so:
 *      /           search within a string
 *        ^         ensure position is at start of the string
 *          \d      match a digit equal to [0-9]
 *            +     quantifier that matches between one and unlimited times
 *      $/          ensure position is at end of the string
 */
export const IS_VALID_NUMBER_STRING = (/^\d+$/);



/**
 * RegEx breaks down like so:
 *      /                   search within a string
 *        ^                 ensure position is at start of the string
 *          \d              match a digit equal to [0-9]
 *            +             quantifier that matches between one and unlimited times
 *              [A-Za-z]    match a single character present within [A-Za-z]
 *                *         quantifier that matches between zero and unlimited times
 *      $/                  ensure position is at end of the string
 */
export const IS_VALID_ALPHA_NUMERIC_STRING = (/^\d+[A-Za-z]*$/);


/**
 * @param {array} arrayOfTerms - List of search terms
 * @param {string} string - The string to compare the arrayOfTerms from
 * @returns {string} If there isn't a match then return "" else return the string found as it is in the string paramater not in the arrayOfTerms
*/
export const SEARCH_FOR_CASE_INSENSITIVE_TERM = (arrayOfTerms=[], string="", returnTermFromTermList=true) => {
    return arrayOfTerms.reduce((accumilator, term, index)=> {

        const re = new RegExp(term,"i"); // Make case insensitive
        const match = string.match(re);
        if(match) {
            const string = (returnTermFromTermList) ? term : match[0];
            accumilator.push(string);
        }
        return accumilator;
    },[]);
}

export const VALIDATE_STACKEXHANGE_URL = /^(https?:\/\/)([-\w]+\.)?(stackexchange|stackoverflow)\.(com)\/(users|jobs\/companies|questions\/tagged)\/[-.\w]+/i;
export const VALIDATE_TWITTER_URL = /^(@\w{1,15})|(((https?:\/\/)(www\.)?twitter\.com\/)@?\w{1,15})$/i;
export const VALIDATE_FACEBOOK_URL = /^(https?:\/\/)?([-.\w]+\.)?(fb|facebook)\.(me|com)\/[-.\w]+/i;
export const VALIDATE_LINKEDIN_URL = /^(https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/(in|pub|groups|company|companies|showcase|organization)/i;
