import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadingIcon from "../components/LoadingIcon";

const LoadingHOC = options => WrappedComponent => {
    const fetchList = options.fetchList ? options.fetchList : [];
    const waitMS = options.wait ? options.wait : 1500;
    const loaderWithMinHeight = options.loaderWithMinHeight
        ? options.loaderWithMinHeight
        : false;

    class LoadingParent extends Component {
        constructor(props) {
            super(props);

            this.state = {
                wait: waitMS > 0
            };
            this.waitTimeout = undefined;
        }
        componentDidMount() {
            if (!this.props.isLoaded) {
                fetchList.forEach(item => {
                    this.props[item]();
                });

                this.waitTimeout = setTimeout(() => {
                    this.setState({ wait: false });
                }, waitMS);
            } else {
                // at the moment, we will reload every time.
                fetchList.forEach(item => {
                    this.props[item]();
                });
            }
        }

        componentWillUnmount() {
            if (typeof this.waitTimeout === "number") {
                clearTimeout(this.waitTimeout);
            }
        }

        render() {
            const { wait } = this.state;
            const { isLoaded } = this.props;
            if (!isLoaded && wait) {
                return null;
            } else if (!isLoaded && !wait) {
                return (
                    <LoadingIcon loaderWithMinHeight={loaderWithMinHeight} />
                );
            }
            return <WrappedComponent {...this.props} />;
        }
    }

    LoadingParent.propTypes = {
        isLoaded: PropTypes.bool.isRequired,
        loadOnce: PropTypes.bool
    };

    LoadingParent.defaultProps = {
        loaderWithMinHeight: false,
        loadOnce: false
    };

    return LoadingParent;
};

export default LoadingHOC;
