import './assets/fonts/AdobeClean/stylesheet.css';
import './styles/index.scss';

import React from 'react';
import { render } from 'react-dom';
import { Provider as ReduxProvider} from "react-redux";

import { ConnectedRouter as Router } from 'react-router-redux';

import store, { historyStore } from './utils/store';
import App from './scenes/App';

import AppContextProvider from './components/Context';


import { Provider, defaultTheme } from '@adobe/react-spectrum';



/**
 * Render the "App" view inside of the client side router
 */
render((<ReduxProvider store={ store }>
            <AppContextProvider>
                <Provider theme={defaultTheme} colorScheme="light">
                    <Router history={ historyStore }>
                        <App />
                    </Router>
                </Provider>
            </AppContextProvider>
        </ReduxProvider>), document.getElementById('app-root'));


