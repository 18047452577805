import { FULFILLED } from 'redux-promise-middleware';

import { PRODUCT_ACTIONS } from '../utils/constants/ActionTypes';


//todo//deprecated//JRP//remove this reducer once UI is updated and switched fully to './versionCompatibilities'
export default (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_ACTIONS.GET_PRODUCT_VERSION_COMPATIBILITIES + "_" + FULFILLED : {
            const submissionId =  action.payload.submissionId;
            const data = action.payload.data;
            state[submissionId] = data;

            break;
        }
        default : {
            return state
        }
    }

    return state;
};