import React, { Fragment, PureComponent } from 'react';

//Sections
import WhyBuildForTheMagentoPlatform from './components/WhyBuildForTheMagentoPlatform';
import MarketplaceSection from './components/MarketplaceExtensionsThemes';
import GettingStartedSection from './components/GettingStarted';
import DeveloperResources from './components/DeveloperResources';
import EnhanceYourVisibiltySection from './components/EnhanceYourVisibiltySection';
import './LandingPage.css';

export default class extends PureComponent {
    render() {
        return (
            <Fragment>
                <div className="col-12 mb-4">
                    <WhyBuildForTheMagentoPlatform />
                </div>
                <div className="col-12 mb-4">
                    <MarketplaceSection />
                </div>
                <div className="col-12 mb-4 bg-light text-dark">
                    <EnhanceYourVisibiltySection />
                </div>
                <div className="col-12 mb-4">
                    <GettingStartedSection/>
                </div>
                <div className="col-12 mb-4">
                    <DeveloperResources/>
                </div>
            </Fragment>
                
        );
    }
}