import React, { useState, useEffect, useRef } from 'react';
import { historyStore } from '../../../utils/store';
import store from '../../../utils/store';

import { Link as RouterLink } from "react-router-dom";
import { Link } from "@react-spectrum/link";

import '../Css/Landing.css';
import Launch_Footer from './footer';
import Launch_Header from './header';
import { View, Form, Button, RadioGroup, Radio, TextArea, TextField, Checkbox } from "@adobe/react-spectrum";
import { URL_ADDRESSES } from "../../../utils/constants/URL_Addresses";
import { createMarketingData } from '../../../actions/adobeLaunch';

const Landing = () => {

        // useRef is to test if it's the first render. If yes, skip validation trigger with the help of needValidation flag
        const firstRender = useRef(true);
        let [needValidation, setNeedValidation] = useState(false);
        let [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

        // All required fields
        let [name, setName] = useState('');
        let [companyName, setCompanyName] = useState('');
        let [email, setEmail] = useState('');
        let [extName, setExtName] = useState('');
        let [extVersionNo, setExtVersionNo] = useState('');
        let [extPkgId, setExtPkgId] = useState('');
        let [isExtPvt, setIsExtPvt] = useState('');
        let [pvtUrl, setPvtUrl] = useState('');
        let [hasFilledAuthor, setHasFilledAuthor] = useState('');
        let [backwardCompatible, setBackwardCompatible] = useState('');
        let [exchangeUrl, setExchangeUrl] = useState('');
        let [authenticatedUser, setAuthenticatedUser] = useState('');

        //All optional fields
        let [orgId, setOrgId] = useState('');
        let [extRepoUrl, setExtRepoUrl] = useState('');
        let [extRelNotes, setExtRelNotes] = useState('');
        let [selected, setSelection] = React.useState(false);


        useEffect (() => {
            if (firstRender.current) {
                firstRender.current = false;
                return;
            }
            setNeedValidation(true);
            enableSubmit();
        });

        const enableSubmit = () => {
            if (name && companyName && email && extName && extVersionNo && extPkgId && isExtPvt && pvtUrl && hasFilledAuthor && backwardCompatible && exchangeUrl && authenticatedUser) {
                setIsSubmitDisabled(false);
            } else {
                setIsSubmitDisabled(true);
            }
        };

        const sanitizeRadio = (val) =>{
             return (val === "yes")
        };

        const handleSubmit = () => {

            // sanitizing the radio inputs
            let s_isExtPvt = sanitizeRadio(isExtPvt);
            let s_authenticatedUser= sanitizeRadio(authenticatedUser);
            let s_backwardCompatible= sanitizeRadio(backwardCompatible);
            let s_hasFilledAuthor= sanitizeRadio(hasFilledAuthor);

            let extdata = {
                "data": {
                    "id": extPkgId,
                    "version": extVersionNo,
                    "display_name": extName,
                    "exchange_url": exchangeUrl,
                    "archive_url": extRepoUrl,
                    "private_url": pvtUrl,
                    "git_tag_to_be_published": "",
                    "git_tag_last_published": "",
                    "documentation_url": "",
                    "release_notes_url": "",
                    "release_notes": extRelNotes,

                    "has_released_as_private": s_isExtPvt,
                    "has_released_source_code": s_authenticatedUser,
                    "has_certified_backward_compatibility": s_backwardCompatible,
                    "has_filled_exchange_url_in_json": selected,
                    "has_filled_author_in_json": s_hasFilledAuthor,

                    "owner": {
                        "org_id": orgId,
                        "company_name": companyName
                    },

                    "developer": {
                        "name": name,
                        "email": email,
                        "sponsor": ""
                    },
                },
            };
            store.dispatch(createMarketingData(extdata,extPkgId));
            historyStore.push(URL_ADDRESSES.ADOBE_LAUNCH_ACKNOWLEDGEMENT);
        };

        return (
            <div>
                <Launch_Header/>
                <div className="col-12 mb-4">
                    <View
                        UNSAFE_className="bgcc"
                        backgroundColor="red-700"
                        top="size-250">
                        <h1 className="mt-4">Adobe Experience Platform Launch Extension Release Request</h1>
                    </View>
                    <div className="mt-4">This form is for Adobe Partners.  Adobe employees should use the &nbsp;
                        <Link>
                            <RouterLink
                                to={URL_ADDRESSES.INTERNAL_ADOBE_LAUNCH}
                                title="Internal Release">
                                Internal Release &nbsp;
                            </RouterLink>
                        </Link>
                        form.
                    </div>
                    <div className="mt-4"><h1>Developer Info</h1></div>
                    <Form isRequired necessityIndicator="label" id="launch-partner-form">
                        <div className="mt-2">
                            { needValidation ?
                                <TextField label="Your Name" width="size-3600" onChange={setName} value={name} validationState={name ? "valid" : "invalid"}/>
                                :
                                <TextField label="Your Name" width="size-3600" onChange={setName} value={name}/>
                            }
                        </div>

                        <div className="mt-2">
                            { needValidation ?
                                <TextField label="Company Name" width="size-3600" onChange={setCompanyName} value={companyName} validationState={companyName ? "valid" : "invalid"}/>
                                :
                                <TextField label="Company Name" width="size-3600" onChange={setCompanyName} value={companyName}/>
                            }
                        </div>

                        <div className="mt-2">
                            <TextField isRequired={false} label="Experience Cloud Org ID" onChange={setOrgId} value={orgId} width="size-3600"/>
                        </div>

                        <div className="mt-2">
                            { needValidation ?
                                <TextField label="Your Email Address" width="size-3600" onChange={setEmail} value={email} validationState={email ? "valid" : "invalid"}/>
                                :
                                <TextField label="Your Email Address" width="size-3600" onChange={setEmail} value={email}/>
                            }
                        </div>

                        <div className="mt-2"><h1>Extension Info</h1></div>

                        <div className="mt-2">
                            { needValidation ?
                                <TextField label="Extension Name" width="size-3600" onChange={setExtName} value={extName} validationState={extName ? "valid" : "invalid"}/>
                                :
                                <TextField label="Extension Name" width="size-3600" onChange={setExtName} value={extName}/>
                            }
                        </div>

                        <div className="mt-2">
                            { needValidation ?
                                <TextField label="Extension Version Number" width="size-3600" onChange={setExtVersionNo} value={extVersionNo} validationState={extVersionNo ? "valid" : "invalid"}/>
                                :
                                <TextField label="Extension Version Number" width="size-3600" onChange={setExtVersionNo} value={extVersionNo}/>
                            }
                        </div>

                        <div className="mt-2">
                            { needValidation ?
                                <TextField label="extension_package ID" width="size-3600" onChange={setExtPkgId} value={extPkgId} validationState={extPkgId ? "valid" : "invalid"}/>
                                :
                                <TextField label="extension_package ID" width="size-3600" onChange={setExtPkgId} value={extPkgId}/>
                            }
                        </div>

                        <div className="mt-2">
                            { needValidation ?
                                <RadioGroup  label="I have released my extension as a Private extension (Required, you must do a Private Release before Adobe can do a Public Release)" value={isExtPvt}  onChange={setIsExtPvt} validationState={isExtPvt ? "valid" : "invalid"}>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </RadioGroup>
                                :
                                <RadioGroup  label="I have released my extension as a Private extension (Required, you must do a Private Release before Adobe can do a Public Release)" value={isExtPvt} onChange={setIsExtPvt}>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </RadioGroup>
                            }
                        </div>

                        <div className="mt-2">
                            { needValidation ?
                                <TextField label="URL for Launch Property with my private extension installed (the URL format is experience.adobe.com/#/@%tenant-id%/companies/%companyID%/properties/%propertyID%/overview)" width="size-3600" onChange={setPvtUrl} value={pvtUrl} validationState={pvtUrl ? "valid" : "invalid"}/>
                                :
                                <TextField label="URL for Launch Property with my private extension installed (the URL format is experience.adobe.com/#/@%tenant-id%/companies/%companyID%/properties/%propertyID%/overview)" width="size-3600" onChange={setPvtUrl} value={pvtUrl}/>
                            }
                        </div>

                        <div className="mt-2">
                            { needValidation ?
                                <RadioGroup
                                    label="I have filled out the author section of extension.json with a name and email"
                                    value={hasFilledAuthor} onChange={setHasFilledAuthor}
                                    validationState={hasFilledAuthor ? "valid" : "invalid"}>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </RadioGroup>
                                :
                                <RadioGroup
                                    label="I have filled out the author section of extension.json with a name and email"
                                    value={hasFilledAuthor} onChange={setHasFilledAuthor}>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </RadioGroup>
                            }

                        </div>

                        <div className="mt-2">
                            <TextField isRequired={false} label="Extension Repo URL" onChange={setExtRepoUrl} value={extRepoUrl} width="size-3600"/>
                        </div>

                        <div className="mt-2">
                            <TextArea isRequired={false} width="size-3600" onChange={setExtRelNotes} value={extRelNotes} label="Please provide a few bullets about the functionality that this extension provides, either about the initial release or the differences between this and the previous version"/>
                        </div>

                        <div className="mt-2">
                            { needValidation ?
                                <RadioGroup
                                    label="I certify that this extension update does not include any of the forbidden changes specified in the Launch extension Backward Incompatibility Standard found here."
                                    value={backwardCompatible} onChange={setBackwardCompatible}
                                    validationState={backwardCompatible ? "valid" : "invalid"}>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </RadioGroup>
                                :
                                <RadioGroup
                                    label="I certify that this extension update does not include any of the forbidden changes specified in the Launch extension Backward Incompatibility Standard found here."
                                    value={backwardCompatible} onChange={setBackwardCompatible}>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </RadioGroup>
                            }

                        </div>

                        <div className="mt-2">
                            { needValidation ?
                                <RadioGroup
                                    label="I acknowledge that the source code in my extension package will be made available to Platform Launch's authenticated users as explained in the Exchange Partner agreement.  This is required if you want your extension to be publicly available in the extension catalog."
                                    value={authenticatedUser} onChange={setAuthenticatedUser}
                                    validationState={authenticatedUser ? "valid" : "invalid"}>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </RadioGroup>
                                :
                                <RadioGroup
                                    label="I acknowledge that the source code in my extension package will be made available to Platform Launch's authenticated users as explained in the Exchange Partner agreement.  This is required if you want your extension to be publicly available in the extension catalog."
                                    value={authenticatedUser} onChange={setAuthenticatedUser}>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </RadioGroup>
                            }
                        </div>

                        <div className="mt-2"><h1>Listing Information</h1></div>

                        <div className="mt-2">
                            { needValidation ?
                                <TextField label="Exchange Listing URL" width="size-3600" onChange={setExchangeUrl} value={exchangeUrl} validationState={exchangeUrl ? "valid" : "invalid"}/>
                                :
                                <TextField label="Exchange Listing URL" width="size-3600" onChange={setExchangeUrl} value={exchangeUrl}/>
                            }
                        </div>

                        <div className="mt-2">
                            <Checkbox isSelected={selected} onChange={setSelection}>I have included the above URL in the "exchangeUrl" field in my Extension Manifest (extension.json file)</Checkbox>
                        </div>

                        <div className="mt-2">
                            <Button variant="cta" id="btnSubmit" isDisabled={isSubmitDisabled} onPress={handleSubmit}>Finish</Button>
                        </div>
                    </Form>
                </div>
                <Launch_Footer/>
            </div>
        );
};

export default Landing;

