import React from 'react';
import { URL_ADDRESSES } from "../../../utils/constants/URL_Addresses";

import {Link} from "@react-spectrum/link";
//styles and images
import partner_img from "../../../assets/images/developer-magento-extension-partner-770x600.jpg";

const EnhanceYourVisibiltySection = () => {
    return (
        <div className="row p-4"> 
            <div className="col-12 col-md-6 align-self-center">
                <h3>
                    Enhance Your Visibility by Becoming a
                    Adobe Commerce  Extension Builder Partner
                </h3>
                <p className="landing-page-wells">
                    Become an official Extension Builder Partner and get preferred 
                    marketing opportunities, a cloud sandbox, prioritized extension 
                    reviews and more. You’ll be part of a trusted network of Adobe Commerce
                    experts that help merchants with end-to-end project implementations 
                    including design, customization, configuration, integration, and deployment.
                </p>
                <Link>
                    <a href={URL_ADDRESSES.EXTERNAL_BECOME_A_COMMERCE_PARTNER} target="_blank">
                        Learn more
                    </a>
                </Link>
            </div>
            <div className="d-none d-md-block col-12 col-md-6">
                <div>
                    <img className="w-100" src={partner_img} alt="Developer Adobe Commerce Extension Partner" />
                </div>
            </div>
        </div>
    );
};

export default EnhanceYourVisibiltySection;