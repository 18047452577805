import { FETCH_API } from '../constants/RestHelpers';
import { IS_LOADING_API_URLS } from '../constants/AppConstants';


const isLoadingURL = (url, isLoading) => ({
    type: IS_LOADING_API_URLS,
    payload:{
        [url]:{
            isLoading
        }
    }, 
})

const fetchAPI = store => next => action => {
    if(action.type === FETCH_API && action.meta) {
 
        const { meta } = action;
        const dispatch = store.dispatch       
        const { url } = meta.url;
        const options = meta.options;
        const body = meta.body || {};
        const successHandler = meta.success;
        const errorHandler = meta.error;

        dispatch(isLoadingURL(url, true));
        

        return fetch(url, {...options, body})
            
            .then( response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                dispatch(isLoadingURL(url, false));
                return response;
            })
            // .then(PARSE_JSON_AND_CHECK_STATUS_CODE)
            .then((response) => response.json())
            .then((json) => {
                return dispatch(successHandler(json));
            })
            .catch( error => dispatch(errorHandler(error)))
    } 
    return next(action);
  };

export default fetchAPI;
