import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getUserEnv } from '../../actions/user';
import { NOTIFICATION_LEVELS } from "../../utils/constants/ActionTypes";
import NotificationBlock from "./";


class EnvNotification extends Component {

    constructor(props) {
        super(props);
    };

    componentDidMount() {
        this.props.getUserEnvData();
    };

    render() {
        const msg = <div className="EnvNotification"> This environment is running in sandbox mode. The expected use for "sandbox mode" is for developers to practice using the public DevPortal REST API.<br/>  To access production mode, please visit <a target="_blank" href="https://commercedeveloper.adobe.com">commercedeveloper.adobe.com</a>.</div>;
        return (
            this.props.envData.in_sandbox_mode ? <NotificationBlock text={msg} level={NOTIFICATION_LEVELS.ERROR}/> : null
        )
    }
}



EnvNotification.propTypes = {
    envData : PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        envData  : state.env
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserEnvData : () => {
            dispatch(getUserEnv())
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnvNotification);
