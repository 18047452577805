import { login as loginService,
         logout as logoutService,
         getUserData as getUserDataService,
         updateUserData as updateUserDataService,
         getUserEnv as getUserEnvService
         } from '../services/user';
import loadingURL from './url';
import { USER_ACTIONS } from "../utils/constants/ActionTypes";
import api from '../utils/apiFetch';
import { REST_URLS_USER_PROFILE_API } from '../utils/constants/URL_Addresses';
import { FETCH_PUT_OPTIONS, FETCH_GET_OPTIONS } from '../utils/constants/RestHelpers';
import { clearProductFormErrors, formError } from './notifications';
import { getUserDataURL } from '../utils/urls';
/**
 * //TODO//update comment for this//JRP//
 *
 * @returns {{type: string, payload: void}}
 */
export const login = () => ({
    type    : USER_ACTIONS.LOGIN,
    payload : loginService()
});

/**
 * //TODO//update comment for this//JRP//
 *
 * @returns {{type: string, payload: void}}
 */
export const logout = () => ({
    type    : USER_ACTIONS.LOGOUT,
    payload : logoutService()
});

/**
 * //TODO//update comment for this//JRP//
 *
 * @returns {{type: string, payload: void}}
 */

const recieveUserData = (user) => ({
    type    : USER_ACTIONS.GET_USER_PROFILE_DATA,
    payload : user,
})

export const getUserData = () => ({
    type    : USER_ACTIONS.GET_USER_PROFILE_DATA,
    payload : getUserDataService()
});

/**
 * //TODO//update comment for this//JRP//
 *
 * @returns {function(*): *}
 */
export const getUserDataPromise = () => (dispatch) => {
    const url = getUserDataURL();
    return api(url, {...FETCH_GET_OPTIONS}, dispatch)
        .then((userData)=>{
            dispatch(loadingURL(url, 'GET', 'success'));
            return dispatch(recieveUserData(userData));
        })
        .catch((error)=>{})
};

export const getUserDataStore = () => (dispatch, getStore) => {
    const value = getStore().user.vendor_name;
    return new Promise((resolve)=>resolve(value));
};


/**
 * //TODO//update comment for this//JRP//
 *
 * @returns {function(*): *}
 */
export const updateUserDataPromise = data => dispatch => dispatch({
    type    : USER_ACTIONS.UPDATE_USER_DATA,
    payload : updateUserDataService(data)
});


const updateUserDataActionCreator = (data) => {
    return {
        type    : USER_ACTIONS.UPDATE_USER_PROFILE_DATA,
        payload : data,
    }
};

//TODO//clean up this action below, its too tightly coupled with view components? why???//
//remove its coupling with clearing product form errors, this shouldnt be happening everytime anypart of the app needs
//to update user data
export const updateUserData = (data, options={}) => (dispatch) => {
    return api(REST_URLS_USER_PROFILE_API.PUT_USER_PROFILE, {...FETCH_PUT_OPTIONS, body:JSON.stringify(data)}, dispatch)
    .then((response)=>{
        if(options.hasOwnProperty('formName') && data.hasOwnProperty('mage_id')) {
            const mageId = data.mage_id;
            dispatch(clearProductFormErrors(mageId));
        }
        return dispatch(updateUserDataActionCreator(response));
    })
    .catch((response)=>{
        if(response.status) {
            // handle 500 errors or errors
            throw new Error(JSON.stringify(response));
        } else {
            // 4xx errors. These will need to be displayed in the form area per product/form
            if(options.hasOwnProperty('formName') && options.hasOwnProperty('mage_id')) {
                const mageId = options.mage_id;
                dispatch(formError(options.formName, mageId, response));
            }
            throw new Error(JSON.stringify(response));
        }
    });   
};

export const getUserEnv = () => ({
    type    : USER_ACTIONS.GET_USER_ENV_DATA,
    payload : getUserEnvService()
});
