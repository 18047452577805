import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';


class TOSDeclined extends Component {
    render() {
        return (<Modal isOpen={this.props.show}
                       ariaHideApp={false}
                       className="tos-model-content-decline"
                       overlayClassName="product-modal-overlay">
            <div className="w-100">
                <div className="magento-h2 m-1">Sorry</div>
                <div className="product-sub-heading magento-h7 m-1">You must agree with our new Terms and Conditions in order to sell on Adobe Commerce Marketplace.</div>

                <input type="button"
                       className="custom-spectrum-Button custom-spectrum-Button--cta"
                       value="Go Back"
                       onClick={this.props.handleContinueButton} />
            </div>
        </Modal>);
    }
}

TOSDeclined.propTypes = {
    show                 : PropTypes.bool.isRequired,
    handleContinueButton : PropTypes.func.isRequired,
};

export default TOSDeclined;