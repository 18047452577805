import React from 'react';
import {URL_ADDRESSES} from "../../../../utils/constants/URL_Addresses";

import {Button} from "@react-spectrum/button";

export default () => (
    <div className="process-overview-tab">
        <h2 className="process-overview-tab-header text-dark">
            Quality Program for Extensions, Themes and Shared Packages
        </h2>

        <div className="process-overview-tab-body text-dark">
            <p>
                Merchants rely on Adobe Commerce Marketplace to easily find new capabilities to enhance their store, and to quickly implement them without introducing conflicts. This is why it’s so important that the extensions and themes offered are of high quality.
            </p>
            <p>
                We’re committed to guiding your submission through a successful launch on Marketplace as well as marketing it to the global Adobe Commerce community.
            </p>

        </div>
        <div className="process-overview-tab-footer">
            <Button variant="primary" elementType="a" href={URL_ADDRESSES.EXTERNAL_PROCESS_OVERVIEW} rel="noopener noreferrer" target="_blank" >View Process Overview</Button>
        </div>
    </div>
);