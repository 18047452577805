import { readCookie } from "../../services/cookies";
import compareVersions from "compare-versions";

import {
    PRODUCT_TYPES,
    JSON_PRODUCT_TYPE,
    ACTION_TYPES,
    PRODUCT_SUBMISSION_MESSAGE_TYPE,
    EQP_STATUS_OVERALL
} from "../../utils/constants/AppConstants";
/** This method grabs element and order for Sort operations **/

import {
    FIND_ALL_SPACES_IN_STRING,
    IS_VALID_NUMBER_STRING
} from "../../utils/constants/RegExHelpers";

export const FIND_SORT_EL_ORDER = id => {
    const arr = id.split("~");
    const el = arr[1];
    let order = arr[0] === "down" ? "-" : "";

    return { el, order };
};

export const compareSemanticVersions = (firstVersion, secondVersion) => {
    let firstVersionSplits = firstVersion.split(".");
    let secondVersionSplits = secondVersion.split(".");

    //make sure the number being compared is a number and not letters or special characters
    const isValidNumber = num => IS_VALID_NUMBER_STRING.test(num);

    //test this is a valid sem ver string
    if (
        !firstVersionSplits.every(isValidNumber) &&
        !secondVersionSplits.every(isValidNumber)
    )
        return NaN;

    //if one sem ver has less "sections" than add another section
    while (firstVersionSplits.length < secondVersionSplits.length)
        firstVersionSplits.push("0");
    while (secondVersionSplits.length < firstVersionSplits.length)
        secondVersionSplits.push("0");

    //cast each part of the strings to a Number
    firstVersionSplits = firstVersionSplits.map(Number);
    secondVersionSplits = secondVersionSplits.map(Number);

    for (let i = 0; i < firstVersionSplits.length; i++) {
        if (secondVersionSplits.length === i) {
            return 1;
        }

        if (firstVersionSplits[i] === secondVersionSplits[i]) continue;
        else if (firstVersionSplits[i] > secondVersionSplits[i]) return 1;
        else return -1;
    }

    if (firstVersionSplits.length !== secondVersionSplits.length) return -1;

    return 0;
};

/**
 * Returns a consistent way to present the status of a product.
 * @param status            - string ... typically from the 'overall_state' of the product
 * @param isDelisted        - bool
 * @param assuranceWorkflow - string {"none", "pass", "fail", "in_progress"}
 * @returns string
 */
export const buildStatusText = (status, isDelisted, assuranceWorkflow) => {
    let statusText = status;
    const tokens = [];

    // App Assurance Program
    if (assuranceWorkflow === 'pass') {
        tokens.push('Assured');
    } else if (assuranceWorkflow === 'fail') {
        tokens.push('Not Assured');
    } else if (assuranceWorkflow === 'in_progress') {
        tokens.push('Pending Assurance');
    }

    // Delisting
    if (isDelisted) {
        tokens.push('Delisted');
    }

    // build status text
    if (tokens.length) {
        statusText += ' (' + tokens.join(', ') + ')';
    }
    return statusText;
}

/**
 * Returns the "LIVE" badge text.  Might include additional tokens.  Examples include:
 * * LIVE
 * * v 1.2.3 LIVE (ASSURED, DELISTED)
 *
 * @param versionNumber      - string ... can be the empty string, or like '3.2.1'.  If present, used as a prefix.
 * @param isDelisted         - bool   ... if true, the "DELISTED" token is added as a suffix.
 * @param assuranceWorkflow  - string ... if "pass" then the "ASSURED" token is added as a suffix.
 * @returns {string}
 */
export const buildLiveBadgeText = (versionNumber, isDelisted, assuranceWorkflow) => {
    let badgeText = '';

    if (versionNumber !== '') {
        badgeText = 'v ' + versionNumber + ' ';
    }

    badgeText += 'LIVE';  // always

    let tokensText = '';
    const tokens = [];
    if (assuranceWorkflow === 'pass') {
        tokens.push('ASSURED');
    }
    if (isDelisted) {
        tokens.push('DELISTED');
    }
    if (tokens.length) {
        tokensText = ' (' + tokens.join(', ') + ')';
    }

    return badgeText + tokensText;
}

export const SHALLOW_OBJECT_COMPARE = (el, order) => {
    return ({ [el]: a }, { [el]: b }) => {
        const comp = a.localeCompare(b);
        return order === "-" ? ~comp + 1 : comp;
    };
};

/* This method is being used in page view report where number of views needs to be aggregated for every product page */
export const GET_AGGREGATED_DATA = objArr => {
    // convert data into a Map with reduce
    const counts = objArr.reduce((prev, curr) => {
        const count = prev.get(curr.page) || 0;
        prev.set(curr.page, {
            views: count.views
                ? Number(curr.views) + count.views
                : Number(curr.views),
            sku: curr.sku
        });
        return prev;
    }, new Map());

    // map counts object back to an array
    let aggregatedArr = [...counts].map(([page, data]) => {
        return { page, data };
    });
    aggregatedArr.sort((obj1, obj2) => obj2.data.views - obj1.data.views); //descending order of pageviews
    return aggregatedArr;
};

/* This method is being used in Analytics page - global tab , where number of views needs to be aggregated for every category */
export const GET_AGGREGATED_CATEGORY_VIEWS_DATA = objArr => {
    // convert data into a Map with reduce
    const counts = objArr.reduce((prev, curr) => {
        const count = prev.get(curr.page) || 0;
        prev.set(curr.page, {
            views: count.views
                ? Number(curr.views) + count.views
                : Number(curr.views),
            date: curr.date
        });
        return prev;
    }, new Map());

    // map counts object back to an array
    let aggregatedArr = [...counts].map(([page, data]) => {
        return { page, data };
    });
    aggregatedArr.sort((obj1, obj2) => obj2.data.views - obj1.data.views); //descending order of pageviews
    return aggregatedArr;
};

/* This method is for Analytics page - Total marketplace page views */
export const GET_AGGREGATED_MARKETPLACE_VIEWS_DATA = objArr => {
    const aggregate = objArr.reduce((a, b) => +a + +b.value, 0);
    return aggregate;
};

/** This method determines top 5 products to be plotted on graph **/
export const GET_PRODUCT_GRAPH_DATA = productPageViewsRawArr => {
    const totalViewCount = GET_AGGREGATED_DATA(productPageViewsRawArr).slice(
        0,
        5
    );
    let newArr = [];
    for (let i = 0; i < totalViewCount.length; i++) {
        for (let j = 0; j < productPageViewsRawArr.length; j++) {
            if (totalViewCount[i].page === productPageViewsRawArr[j].page) {
                let tempObj = {
                    views: productPageViewsRawArr[j].views,
                    date: productPageViewsRawArr[j].date,
                    page: productPageViewsRawArr[j].page,
                    sku: productPageViewsRawArr[j].sku,
                    product_name: productPageViewsRawArr[j].product_name
                };
                newArr.push(tempObj);
            }
        }
    }
    return newArr;
};

/**
 * Utility helper to remove all spaces from a string, this is used for cleaning up urls
 *
 * @param url {String} url string to be checked against
 *
 * @returns {String} url string with all spaces removed
 */
export const CLEAN_EXTRANEOUS_URL_CHARACTERS = url =>
    url.replace(FIND_ALL_SPACES_IN_STRING, "");

export const BUILD_KEY_VALUE = (values, names) => {
    const keys = Object.keys(values);
    return keys.map(key => {
        return { key: names[key], value: values[key] };
    });
};

/** This method reads mageId from cookies . Used in calls made for Report functionality **/

export const READ_MAGEID_FROM_COOKIES = () => {
    let mageID = null;
    const cookies = readCookie();
    if (cookies && cookies.mage_id) {
        mageID = cookies.mage_id;
    }
    return mageID;
};

/**
 * This method reads in categories and sub categories like ["//NewCategory//NewSubCategory2","//NewCategory//NewSubCategory2"]
 * Then returns {NewCategory:[sub categories,...]}
 **/

export const NORMALIZE_CATEGORIES = categories => {
    return categories.reduce((acc, value, index) => {
        const catAndSubCat = value.name.replace(/^\/\//i, "").split("//");
        const type = value.type;
        if (!acc.hasOwnProperty(type)) {
            acc[type] =
                catAndSubCat.length === 1
                    ? { [catAndSubCat[0]]: [] }
                    : { [catAndSubCat[0]]: [catAndSubCat[1]] };
        } else {
            if (!acc[type].hasOwnProperty(catAndSubCat[0])) {
                acc[type][catAndSubCat[0]] =
                    catAndSubCat.length === 1 ? [] : [catAndSubCat[1]];
            } else {
                acc[type][catAndSubCat[0]].push(catAndSubCat[1]);
            }
        }

        return acc;
    }, {});
};

/** 
 * recieve:
 *  "categories": [                                  // totally replaces any previous list
        "//parentA//childA",
        "//parentA//childB",
        "//parentB",
        "//parentC//childD",
        ...
      ]
    
* return:
    {
        category: name,
        subCategories: [...]
    }
**/
export const NORMALIZE_PRODUCT_CATEGORY = categories => {
    return categories.reduce((acc, value, index) => {
        const catAndSubCat = value.replace(/^\/\//i, "").split("//");
        if (!acc.hasOwnProperty("category")) {
            acc.category = catAndSubCat[0];
            acc.subCategories =
                catAndSubCat.length === 1 ? [] : [catAndSubCat[1]];
        } else if (catAndSubCat.length > 1) {
            acc.subCategories.push(catAndSubCat[1]);
        }

        return acc;
    }, {});
};

export const PRODUCT_TYPE = type => {
    const { EXTENSION, THEME, APP } = PRODUCT_TYPES;
    const UCFType = type.charAt(0).toUpperCase() + type.slice(1);
    let productType = "";
    switch (UCFType) {
        case EXTENSION:
            productType = EXTENSION;
            break;
        case THEME:
            productType = THEME;
            break;
        case APP:
            productType = APP;
            break;
        default:
            productType = "";
    }
    return productType;
};

//obj looks like: [{ edition:'CE', version: [...]}, {edition:'EE',version: [..]}]
export const HAS_EDITION_SUPPORT = (version_compatibility, edition) => {
    if (!version_compatibility.length) {
        return false;
    }
    return !!version_compatibility.find(
        item => item.edition === edition && item.versions.length
    );
};

export const HAS_EE_CE_ECE_SUPPORT = version_compatibility => {
    return {
        CE:  HAS_EDITION_SUPPORT(version_compatibility, "CE"),
        EE:  HAS_EDITION_SUPPORT(version_compatibility, "EE"),
        ECE: HAS_EDITION_SUPPORT(version_compatibility, "ECE")
    };
};

// This method will be deprecated once Cloud implementation is complete on all the marketing sections - PS
export const HAS_EE_CE_SUPPORT = version_compatibility => {
    return {
        CE: HAS_EDITION_SUPPORT(version_compatibility, "CE"),
        EE: HAS_EDITION_SUPPORT(version_compatibility, "EE")
    };
};

//This method checks the "actions_now_available" object we get back from the api, this *should* probably be the source
//or truth for the views/modes/states that the technical or marketing section follow
//TODO//a ticket should be created to use `actions_now_available` vs `eqp_status` for the view states//JRP//
/**
 * Takes the "actions_now_availabe" object and a section to check against,  then checks to see if "submit" and "draft"
 * are actions that are available.  if so, return true, and the UI should respond enabling those buttons
 *
 * @param actionsAvailable : list containing available actions for marketing, technical, or overall
 * @param validDraftModeValues : array that contains the valid actions available,  overriding this is optional
 *
 * @constructor
 */

export const ALLOW_EDIT_AND_SUBMIT_MODE = (
    actionsAvailable = [],
    validDraftModeValues = ["submit", "draft"]
) => actionsAvailable.every(action => validDraftModeValues.includes(action));


// //probably rename this when the above method gets more wide spread and checked with for each section//JRP//

export const IS_IN_DRAFT = (
    product_epq_status,
    {
        DRAFT,
        RECALLED,
        REJECTED,
        APPROVED,
        APPROVED_WITH_MODIFICATIONS_PENDING = ""
    },
    options = { actionType: "", overalEQP: "" }
) => {
    // if action type is marketing, overall status is "release to store" and the marketing status is "approved" allow the user to save section and submit
    if (
        APPROVED_WITH_MODIFICATIONS_PENDING &&
        product_epq_status === APPROVED_WITH_MODIFICATIONS_PENDING &&
        options.actionType &&
        options.actionType === ACTION_TYPES.MARKETING
    ) {
        return true;
    }

    //the marketing submission section can get into a state where eqp_status:overall is 'in_progress' but
    // eqp_status:marketing is 'approved_with_modifications_pending' which was causing the UI to freeze up
    // this handles that use case
    else if (
        options.overalEQP === EQP_STATUS_OVERALL.IN_PROGRESS &&
        (options.actionType === ACTION_TYPES.MARKETING &&
            product_epq_status === APPROVED_WITH_MODIFICATIONS_PENDING)
    ) {
        return true;
    }

    return [DRAFT, RECALLED, REJECTED].includes(product_epq_status);
};

export const SUBMISSION_MESSAGE_TYPE = ({
    productType = "",
    actionType = ""
}) => {
    const {
        EXTENSION_THEME_MARKETING,
        EXTENSION_THEME_TECHNICAL,
        SHARED_PACKAGE_TECHNICAL
    } = PRODUCT_SUBMISSION_MESSAGE_TYPE;

    const messages = {
        [JSON_PRODUCT_TYPE.EXTENSION.toLowerCase()]: {
            [ACTION_TYPES.TECHNICAL.toLowerCase()]: EXTENSION_THEME_TECHNICAL,
            [ACTION_TYPES.MARKETING.toLowerCase()]: EXTENSION_THEME_MARKETING
        },
        [JSON_PRODUCT_TYPE.THEME.toLowerCase()]: {
            [ACTION_TYPES.TECHNICAL.toLowerCase()]: EXTENSION_THEME_TECHNICAL,
            [ACTION_TYPES.MARKETING.toLowerCase()]: EXTENSION_THEME_MARKETING
        },
        [JSON_PRODUCT_TYPE.APP.toLowerCase()]: {
            [ACTION_TYPES.TECHNICAL.toLowerCase()]: EXTENSION_THEME_TECHNICAL,
            [ACTION_TYPES.MARKETING.toLowerCase()]: EXTENSION_THEME_MARKETING
        },
        [JSON_PRODUCT_TYPE.SHARED_PACKAGE.toLowerCase()]: {
            [ACTION_TYPES.TECHNICAL.toLowerCase()]: SHARED_PACKAGE_TECHNICAL
        }
    };

    if (
        messages.hasOwnProperty(productType) &&
        messages[productType].hasOwnProperty(actionType)
    ) {
        return messages[productType][actionType];
    }
    return "";
};

export const IS_EQP_STATUS_OVERALL_CANCELED = (overall = "") => {
    const { CANCELED_BY_DEVELOPER, CANCELED_BY_ADMIN } = EQP_STATUS_OVERALL;
    const canceledStatuses = [CANCELED_BY_DEVELOPER, CANCELED_BY_ADMIN];

    return canceledStatuses.includes(overall);
};

export const MERGE_ARRAY_OF_OBJECTS_REMOVE_DUPLICATES = (
    oldArr,
    updatedArr,
    key = ""
) => {
    let objList = {};
    oldArr.forEach(obj => {
        objList[obj[key]] = Object.assign(obj);
    });
    // Any duplicates will get overwritten.
    updatedArr.forEach(obj => {
        objList[obj[key]] = Object.assign(obj);
    });
    let mergedArray = [];

    for (let p in objList) {
        if (objList.hasOwnProperty(p)) mergedArray.push(objList[p]);
    }
    return mergedArray;
};

export const SORT_BY_PRODUCT_VERSION = (versionA, versionB) => {
    if (versionA === "" || versionB === "") {
        if (versionA === "" && versionB === "") {
            return 0;
        } else if (versionA === "") {
            return -1;
        }
        return 1;
    }

    return compareVersions(versionA, versionB);
};


/**
 * Checks url . if it starts with  'http' then its fine otherwise  add protocol to it .
 */

export const SANITIZE_URL = url => {
    return url.toLowerCase().startsWith("http") ? url : `http://${url}`;
};


// compareVersions.compare('10.1.8', '10.0.4', '>'); // return true
// compareVersions.compare('10.0.1', '10.0.1', '='); // return true
// compareVersions.compare('10.1.1', '10.2.2', '<'); // return true
// compareVersions.compare('10.1.1', '10.2.2', '<='); // return true
// compareVersions.compare('10.1.1', '10.2.2', '>='); // return false
export const COMPARE_VERSIONS = (versionA="", versionB="", operator=">") => {
    return compareVersions.compare(versionA, versionB, operator);
}

