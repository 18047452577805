import React from 'react';
import hero_img from "../../../assets/images/DevPortal-Homepage-Illustration.png";

const WhyBuildForTheMagentoPlatform = () => {
    return(
        <div className="row align-items-center">
            <div className="col-12 col-md-6 order-2 order-md-1">
                <div className="row">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <h3 className="magento-h2 text-right text-dark">Why Build for the</h3>
                        <h3 className=" magento-h2 text-dark text-right">Adobe Commerce Platform?</h3>
                        <p className="border-left pl-4 landing-page-wells text-dark">
                            Join our community of incredible innovators and make your commerce
                            functionality available to the fastest growing base of ecommerce merchants.
                            Build your business by providing new capabilities for Adobe Commerce stores
                            through enterprise apps and extensions.
                        </p>
                    </div>
                </div>
            </div>
             <div className="d-none d-md-block col-12 col-md-6 order-2 order-md-1">
                <div className="row h-100">
                    <div>
                        <img className="w-100" src={hero_img} alt="Why Build for the Magento Platform?"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyBuildForTheMagentoPlatform;