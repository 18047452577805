import { combineReducers } from 'redux';
import { IS_LOADING_API_URLS } from '../utils/constants/AppConstants';
import { LOADING_URL } from '../utils/constants/ActionTypes';



export const apiUrlRequests = (state = {}, action) => {
    switch (action.type) {
        case IS_LOADING_API_URLS : {
            state = {...state, ...action.payload};
            break;
        }
        default : {
            return state
        }
    } 
    return state;
};

export const loadingRequests = ( state = {}, action) =>{
    switch (action.type) {
        case LOADING_URL : {
            return {...state, ...action.url }
        }
        default : {
            return state;
        }
    }
}

export default combineReducers({
    apiUrlRequests,
    loadingRequests,
});
