import { createStore, applyMiddleware, compose } from 'redux';

import { devToolsEnhancer } from 'redux-devtools-extension/';
//import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction';

import reduxPromise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import { fetchAPI, polling } from '../utils/middleware';


/**
 * Create an instance of `Browser History` to be used in the redux store and the react-router history
 */
const historyStore = createHistory();



/**
 * Create an instance of a redux store.
 *
 * This line can be broken down as follows:
 *
 *      //uses the redux to compose methods from right to left.  i.e, will apply any existing `middlewares`, then will
 *      • create and apply the `routerMiddleware` passing in the `historyStore` object.
 *      • apply the `reduxPromise' middleware from `redux-promise-middleware`.  This allows us to use promises within
 *        redux to get data from a server/api call
 *      • apply the `thunk` middleware from `redux-thunk`. This us allows us to return methods from an action
 * compose(applyMiddleware(routerMiddleware(historyStore), reduxPromise()))
 *      //The below 2 code lines, basically create redux store and passes in the other arguments.
 * (createStore)
 *      • rootReducer is a combined object of any reducers we need to combine together,
 *      • {} is the blank initial state
 *      • devToolsEnhancer is a chrome extension that allows us to track the redux state
 * (rootReducer, {}, devToolsEnhancer({}))
 *
 */


let middleware = [
    routerMiddleware(historyStore),
    reduxPromise(),
    thunk,
    fetchAPI,
    polling];

const store = compose(applyMiddleware(...middleware))(createStore)(rootReducer, {}, devToolsEnhancer({}));


/**
 * export the store as default, this builds out the store using the combined reducers and the initialstate
 */
export { store as default, historyStore, middleware };