import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { URL_ADDRESSES } from '../../utils/constants/URL_Addresses';
import NotificationCard from '../../components/NotificationBlock/NotificationCard';



const userNotification = (props) => {
    const { has_completed_profile, tax_review_status } = props.userData;
    const msg = [];
    const linkArray = [];
    const taxLinkArray = [];
    // if user isn't logged in, mage_id would be null

    const renderLinks = (links) => {
        return links.map((link, index, array)=>{
            let punctuation = "";
            if(index && index+1 === array.length && array.length > 1) {
                punctuation = " and ";
            } else if(index && index+1 !== array.length  && array.length > 1) {
                punctuation = ", ";
            }
            return (
                <Fragment key={index} >
                    {punctuation}
                    <Link className="menu-content-notification" to={link.to}>{link.label}</Link>
                </Fragment>
            );
        });
    }
    
    if (props.userData.mage_id !== null && !has_completed_profile) {
        linkArray.push({
            to: URL_ADDRESSES.INTERNAL_ACCOUNT + URL_ADDRESSES.INTERNAL_INFORMATION,
            label: "Account Information",
        });
        linkArray.push({
            to: URL_ADDRESSES.INTERNAL_ACCOUNT + URL_ADDRESSES.INTERNAL_ACCOUNT_PROFILE,
            label: "Marketplace Profile",
        });

        // tax_review_status values for (Tax Forms was not submitted)
        // 0 - unspecified
        // 1 - US resident, requires W9
        if (tax_review_status === null || tax_review_status < 2) {
            linkArray.push({
                to: URL_ADDRESSES.INTERNAL_ACCOUNT + URL_ADDRESSES.INTERNAL_PROFILE_TAX_FORMS + '/',
                label: "Tax Information",
            });
        }

        // tax_review_status values for (Tax Form is submitted and not approved)
        // 2 - documents have been uploaded, but still needs review
        // FYI 3 - reviewed and accepted
        if (tax_review_status ===  2) {
            taxLinkArray.push({
                to: URL_ADDRESSES.INTERNAL_ACCOUNT + URL_ADDRESSES.INTERNAL_PROFILE_TAX_FORMS + '/',
                label: "Tax Information",
            });
        } 
        // If Tax Forms was not submitted, display this information.
        if (linkArray.length && !taxLinkArray.length) {
            msg.push([
                <Fragment>
                    Please be sure to complete your { renderLinks(linkArray) } selection and submission. You will not be able 
                    to launch on Marketplace until all the required information is complete and verified by our review 
                    team, even if your submission has been approved.
                </Fragment>
            ]) 
        }
         // If Tax Form is submitted and not approved, display this information instead.
        else if (taxLinkArray.length) {
            msg.push([
                <Fragment>
                    Please make sure you have completed your { renderLinks(linkArray) }. If you have submitted
                    your { renderLinks(taxLinkArray) }, it is awaiting verification. You will not be able to 
                    launch on Marketplace until all the required information is complete and verified by our 
                    review team, even if your submission has been approved.
                </Fragment>
            ]) 
        }
    }
    return msg.map((msgText, index) =>{
        return (
            <NotificationCard key={index} serverError={props.serverError} warning={msgText} />
        )
    });
}

userNotification.propTyps = {
    userData: PropTypes.shape({
        has_completed_profile: PropTypes.bool.isRequired,
        tax_review_status: PropTypes.number, //this isn't reaquired because it could be null from the backend
    })
}

userNotification.defaultProps = {
    has_completed_profile: true,
}

export default userNotification;