import { LOCATION_CHANGE } from 'react-router-redux';

export default (state = [], action, ...rest) => {
    switch (action.type) {
        case LOCATION_CHANGE : {
            //todo//based on URL we may need to make a call to a service to refresh data
            //maybe have a factory method to determine the base url hash and react accordingly//JRP//
            state.push(action);
            break;
        }
        default : {
            return state;
        }
    }

    return state;
};