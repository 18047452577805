import {LOADING_URL} from '../utils/constants/ActionTypes';

const loadingURL = (url, command, status) => ({
    type: LOADING_URL,
    url: {
        [url]: {
            dateTime:(new Date()),
            command,
            status,
            }
        }
})

export default loadingURL;