import React, { Fragment} from 'react';
import PropTypes from "prop-types";

import { EQP_STATUS_TECHNICAL, EQP_STATUS_MARKETING, PRODUCT_SUBMISSION_MESSAGE_TYPE  } from '../../utils/constants/AppConstants';
import NotificationBlock from './';
import { MESSAGES_NOTIFICATION } from '../../utils/constants/Messages';
import { NOTIFICATION_LEVELS } from '../../utils/constants/ActionTypes';



const NotificationCard = (props) => {
    const {eqp_status, eqp_type, handleCancelNotification, show_draft_notification, show_saved_notification, formError, submitSuccess, serverError, warning} = props;
    const { TECHNICAL_NEW_NOTIFICATION, DRAFT_SAVED_NOTIFICATION } = MESSAGES_NOTIFICATION;
    const { INFO, WARNING,ERROR, NOTIFICATION } = NOTIFICATION_LEVELS;
    const fragments = [];
    let keyIndex = 0;

    if(eqp_type) {
        let eqp_status_types = {};
        if (eqp_type === 'marketing') {
            eqp_status_types = EQP_STATUS_MARKETING;
        } else {
            eqp_status_types = EQP_STATUS_TECHNICAL;
        }


        if (show_draft_notification && eqp_status_types!==EQP_STATUS_MARKETING && ((eqp_status_types.DRAFT === eqp_status) || (eqp_status_types.UNKNOWN === eqp_status) )) {
            fragments.push(<NotificationBlock key={keyIndex} text={ <Fragment><strong>Before you begin:</strong> {TECHNICAL_NEW_NOTIFICATION}</Fragment> }
                level={ INFO }
                handleNotificationCancel={()=> handleCancelNotification('show_draft_notification')} />);

                keyIndex += 1;
        }else if (!show_draft_notification && show_saved_notification) {
            fragments.push(<NotificationBlock key={keyIndex} text={ DRAFT_SAVED_NOTIFICATION }
                                    level={ NOTIFICATION }
                                    handleNotificationCancel={()=> handleCancelNotification('show_saved_notification')} />);
            keyIndex += 1;
        }
    }
   
    if(submitSuccess) {
        const {EXTENSION_THEME_MARKETING, EXTENSION_THEME_TECHNICAL, SHARED_PACKAGE_TECHNICAL} = PRODUCT_SUBMISSION_MESSAGE_TYPE;
        if(submitSuccess === EXTENSION_THEME_MARKETING) {
            
            fragments.push(<NotificationBlock
                key={keyIndex}
                text={ <Fragment>
                    Your Marketing Submission is complete! Estimated time until review is approximately <strong>10-14 business days</strong> based on our current queue.
                    </Fragment> }
                level={INFO}
                handleNotificationCancel={handleCancelNotification} />)
            keyIndex += 1;      
        } else if(submitSuccess === EXTENSION_THEME_TECHNICAL) {
            fragments.push(<NotificationBlock
                key={keyIndex}
                text={ <Fragment>
                    Your Technical Submission is complete! We will begin running automated 
                    tests shortly. This is followed by manual q/a - the current estimated 
                    wait for manual q/a assignment is <strong>15 business days</strong> based 
                    on our current queue.
                    </Fragment> }
                level={INFO}
                handleNotificationCancel={handleCancelNotification} />)
            keyIndex += 1;      
        } else if(submitSuccess === SHARED_PACKAGE_TECHNICAL) {
            fragments.push(<NotificationBlock
                key={keyIndex}
                text={ <Fragment>
                    Your submission is complete! Estimated time until review is approximately <strong>10 business days</strong> based on our current queue.
                    </Fragment> }
                level={INFO}
                handleNotificationCancel={handleCancelNotification} />)
            keyIndex += 1;      
        }
        
    }
    
    if(warning.length) {
        warning.map((msg)=>{
            fragments.push(
                <NotificationBlock
                    key={keyIndex}
                    text={ <Fragment>{msg}</Fragment> }
                    level={WARNING} />
            );
            keyIndex += 1;
        })
    }

    // server side api errors
    if(formError.length) {
        fragments.push(<NotificationBlock key={keyIndex} text={ <Fragment><strong>{formError[0].error.message}</strong></Fragment> }
            level={WARNING} />)
        keyIndex += 1;      
    }

    if(serverError) {
        fragments.push(<NotificationBlock key={keyIndex} text={ <Fragment><strong>{serverError}</strong></Fragment> }
            level={ERROR} />)
        keyIndex += 1;      
    }
    

    return (fragments.length) ? fragments : null;
    // return null;

}

NotificationCard.propTypes = {
    eqp_status: PropTypes.string,
    eqp_type: PropTypes.string,
    handleCancelNotification: PropTypes.func,
    show_draft_notification: PropTypes.bool,
    show_saved_notification: PropTypes.bool,
    serverError: PropTypes.string,
    submitSuccess: PropTypes.string,
    formError: PropTypes.array,
    warning: PropTypes.array,
}

NotificationCard.defaultProps = {
    formError: [],
    warning: [],
}

export default NotificationCard;