import { FULFILLED } from 'redux-promise-middleware';

import { USER_API_KEYS_ACTIONS } from '../utils/constants/ActionTypes';

const initialState = {
    apiKeysData : []
};

const apiKeysReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_API_KEYS_ACTIONS.GET_USER_API_KEYS + "_" + FULFILLED : {
            state = {
                ...state,
                apiKeysData : action.payload
            };
            break;
        }

        case USER_API_KEYS_ACTIONS.CREATE_USER_API_KEY + "_" + FULFILLED : {
            let newApiKeyList = action.payload;
            let newState = {...state};
            for (let i=0; i < newApiKeyList.length; i++) {
                let _currentKey = newApiKeyList[i];

                if (_currentKey.code === 200) {
                    newState.apiKeysData.push(_currentKey);
                }
            }

            state = {...state, apiKeys:newState.apiKeys};
            break;
        }
        case USER_API_KEYS_ACTIONS.REGENERATE_USER_API_KEY + "_" + FULFILLED : {

            let newApiKeyList = action.payload;
            let existinglist = {...state};

            for (let i=0; i < existinglist.apiKeysData.length; i++) {
                let _currentExistingKey = existinglist.apiKeysData[i];
                for (let j=0; j < newApiKeyList.length; j++) {
                    let _currentNewKey = newApiKeyList[j];
                    if (_currentExistingKey.app_name === _currentNewKey.app_name) {
                        _currentExistingKey.app_id = _currentNewKey.app_id;
                        _currentExistingKey.app_secret = _currentNewKey.app_secret;
                    }
                }
            }

            state = {...state, ...existinglist};
            break;
        }

        case USER_API_KEYS_ACTIONS.DELETE_USER_API_KEY + "_" + FULFILLED : {
            let existingState = {...state};
            let newState = {apiKeysData:[]};

            for (let i=0; i<existingState.apiKeysData.length; i++) {
                if (existingState.apiKeysData[i].app_name !== action.payload) {
                    newState.apiKeysData.push(existingState.apiKeysData[i]);
                }
            }

            state = {...state, ...newState};
            break;
        }
        default : {
            return state;
        }
    }

    return state;
};

export default apiKeysReducer;
