import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import userNotification from '../../components/NotificationBlock/userProfileNotification';
import EnvNotification from '../../components/NotificationBlock/EnvironmentNotification';
//import GlobalNotification from '../../components/NotificationBlock/GlobalNotification';

//URL history data store
import { historyStore } from '../store';

import { getUserData, getUserDataPromise, login, logout, updateUserDataPromise } from '../../actions/user';
import { readCookie, clearCookies } from '../../services/cookies';
import { URL_ADDRESSES } from '../constants/URL_Addresses';

//Default view component
import LandingPage from '../../scenes/Landing';
import AdobeLaunchLandingPage from '../../scenes/AdobeLaunch/Landing';
import AdobeLaunchInternalPage from '../../scenes/AdobeLaunch/Internal';
import AdobeLaunchAcknowledgementPage from '../../scenes/AdobeLaunch/Acknowledgement';
import TOSModal from '../../components/Modals/TOSModal';


//navigation
import TopNavigation from '../../components/Navigation/NavigationTop';
import BottomNavigation from '../../components/Navigation/NavigationBottom';
import {NOTIFICATION_LEVELS} from "../constants/ActionTypes";
import NotificationBlock from "../../components/NotificationBlock";

import {URL_CODES} from "../constants/URL_Codes";
import {URL_ERROR_MESSAGES} from "../constants/ErrorMessages";


const checkAuthentication =  (props, component) => {
    const {userData = {}, getUserDataPromise, location:{pathname}, login} = props;

    const accountProfileURL = URL_ADDRESSES.INTERNAL_ACCOUNT + URL_ADDRESSES.INTERNAL_ACCOUNT_PROFILE;

    const showDefaultComponent = (error) => {
        clearCookies();

        if (URL_ADDRESSES.EXTERNAL_ADOBE_LAUNCH  == pathname) {
            return AdobeLaunchLandingPage;
        }else if (URL_ADDRESSES.INTERNAL_ADOBE_LAUNCH  == pathname) {
            return AdobeLaunchInternalPage;
        }else if (URL_ADDRESSES.ADOBE_LAUNCH_ACKNOWLEDGEMENT  == pathname) {
            return AdobeLaunchAcknowledgementPage;
        }

        if (URL_ADDRESSES.INTERNAL_HOME !== pathname)
            historyStore.replace(URL_ADDRESSES.INTERNAL_HOME);

        if ((URL_ADDRESSES.INTERNAL_EXTENSIONS === pathname) || (URL_ADDRESSES.INTERNAL_THEMES === pathname) || (URL_ADDRESSES.INTERNAL_APPS === pathname)) {
            login();
            return;
        }

        if (error)
            console.error("Error in Authenticated Route: ", error);

        return LandingPage;
    };

    const showSelectedComponent = (userData) => {
        const {vendor_name = ""} = userData;

        //TODO//show notification to user about having to set vendor name
        //check that we're not already on the profile page and that the vendor name isnt set
        if ((accountProfileURL !== pathname) && (vendor_name === "" || vendor_name === null))
            historyStore.push(URL_ADDRESSES.INTERNAL_ACCOUNT + URL_ADDRESSES.INTERNAL_ACCOUNT_PROFILE);
        else if (!userData.has_accepted_tos && (accountProfileURL !== pathname)) //vendor name needs to be set first before updating TOS
            return TOSModal;
        else
            return component;
    };

    let { mage_id = "" } = readCookie();


    if (userData.isFullProfile) {
        return showSelectedComponent(userData);
    } else {
        if (mage_id !== "") {
            getUserDataPromise()
                .then(({payload}) => {
                    return showSelectedComponent(payload)
                })
                .catch(error => {
                    return showDefaultComponent(error)
                });
        } else {
            return showDefaultComponent();
        }
    }

};

const AuthenticatedRouteWithNav = ({component, ...props}) => {
    const { userData:{ mage_id=null } = {}, location:{search} } = props;

    if ((URL_ADDRESSES.EXTERNAL_ADOBE_LAUNCH  === props.location.pathname) || (URL_ADDRESSES.INTERNAL_ADOBE_LAUNCH  === props.location.pathname) || (URL_ADDRESSES.ADOBE_LAUNCH_ACKNOWLEDGEMENT  === props.location.pathname)) {
        return <Fragment>
            <div className="row">
                <Route {...props} component={(checkAuthentication(props, component))}/>
            </div>
        </Fragment>

    } else {
        return <Fragment>
            <div className="row">
                <TopNavigation
                    userData={props.userData}
                    handleLogin={props.login}
                    handleLogout={props.logout}/>
            </div>
            <div className="row">
                <EnvNotification/>

                {/* if an error code is sent back from backend request, then show error to user */}
                {search.split("?").some(item => item.includes(URL_CODES.UNAUTHORIZED_USER)) ?
                    <div className="container p-3">
                        <NotificationBlock text={<Fragment>{URL_ERROR_MESSAGES.UNAUTHORIZED_USER}</Fragment>}
                                           level={NOTIFICATION_LEVELS.ERROR}/>
                    </div> : null
                }
                <div className="container">
                    {userNotification(props)}
                    {/* //TODO//maybe add the notification component here so it's centralized//JRP I concur RCC */}
                </div>
            </div>
            <div className="row">
                <Route {...props} component={(checkAuthentication(props, component))}/>
            </div>
            <div className="row">
                <div className='col-sm-12 p-0'>
                    <BottomNavigation userData={props.userData}/>
                </div>
            </div>
        </Fragment>
    }

};

AuthenticatedRouteWithNav.propTypes = {
    location : PropTypes.object.isRequired,
    userData : PropTypes.object.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func
    ]).isRequired,

    login                 : PropTypes.func.isRequired,
    logout                : PropTypes.func.isRequired,
    getUserData           : PropTypes.func.isRequired,
    getUserDataPromise    : PropTypes.func.isRequired,
    updateUserDataPromise : PropTypes.func.isRequired
};

const mapStateToProps = ({ user:userData, notifications, errors:{serverError} }) => ({ userData, notifications, serverError });
const mapDispatchToProps = dispatch => ({
        login                 : () => { dispatch( login()) },
        logout                : () => { dispatch( logout()) },
        getUserData           : () => { dispatch( getUserData()) },
        getUserDataPromise    : () => ( dispatch( getUserDataPromise()) ),
        updateUserDataPromise : data => ( dispatch( updateUserDataPromise(data)) )
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRouteWithNav));