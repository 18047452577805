import React from "react";
import classNames from "classnames";
import FaSpinner from "react-icons/lib/fa/spinner";
import PropTypes from "prop-types";

const LoadingIcon = props => {
    const loaderClass = classNames(
        "d-flex",
        "justify-content-center",
        "h-100",
        "w-100",
        {
            "bs-body-section": props.loaderWithMinHeight
        }
    );

    return (
        <div className={loaderClass}>
            <FaSpinner className="magento-h2 fa-pulse align-self-center" />
        </div>
    );
};

LoadingIcon.propTypes = {
    loaderWithMinHeight: PropTypes.bool
};
LoadingIcon.defaultProps = { loaderWithMinHeight: false };

export default LoadingIcon;
