import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import {URL_ADDRESSES} from "../../../utils/constants/URL_Addresses";

import MdClose from 'react-icons/lib/md/close';

import './TOSModal.css'



class TOSAccept extends Component {

    constructor(props) {
        super(props);

        this.state = { accept_tos_checked : false }
    }

    render() {
        return (<Modal isOpen={this.props.show}
                       ariaHideApp={false}
                       className="tos-model-content"
                       overlayClassName="product-modal-overlay">

            <div className="close-button" onClick={this.props.handleCancelButton}><MdClose/></div>
            <div className="magento-h5">Please Accept Our Terms of Service</div>
            <div className="product-sub-heading magento-h7">This is required before making a submission to the Adobe Commerce Marketplace</div>

            <div className="d-flex h-fit">
                <label className="checkmark-container">
                    <input type="checkbox" name="accepts_tos"
                           checked={this.state.accept_tos_checked}
                           onChange={({target:{checked}}) => {this.setState({accept_tos_checked:checked})}} />
                    <div className="checkmark"/>
                </label>
                <div className="new-labels">By checking the box and clicking the "I Agree" button, I hearby accept the terms of the <a target="_blank" href={URL_ADDRESSES.EXTERNAL_MARKETPLACE_TERMS_MASTER}>Commerce Marketplace Master Terms</a> and <a target="_blank" href={URL_ADDRESSES.EXTERNAL_MARKETPLACE_TERMS_DEVELOPMENT}>Commerce Marketplace Development Terms</a>.</div>
            </div>

            <div className="tos_modal_action_buttons_container">
                <input type="button"
                       className="custom-spectrum-Button custom-spectrum-Button--primary m-1"
                       value="Cancel"
                       onClick={this.props.handleCancelButton} />
                <input type="button"
                       className={`${this.state.accept_tos_checked ? "custom-spectrum-Button custom-spectrum-Button--cta" : "custom-spectrum-Button custom-spectrum-Button--cta-disabled"} m-1 pl-3 pr-3`}
                       value="I Agree"
                       disabled={!this.state.accept_tos_checked}
                       onClick={this.props.handleContinueButton} />
            </div>
        </Modal>);
    }
}

TOSAccept.propTypes = {
    show                 : PropTypes.bool.isRequired,
    handleCancelButton   : PropTypes.func.isRequired,
    handleContinueButton : PropTypes.func.isRequired,
};

export default TOSAccept;