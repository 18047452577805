import React from 'react';
import PropTypes from 'prop-types';
import { URL_ADDRESSES } from '../../../utils/constants/URL_Addresses';

//images
import magento_logo from '../../../assets/images/magento/Adobe_Corporate_Horizontal_Lockup_White_RGB.svg';
import icon_facebook from '../../../assets/images/social/social_facebook.svg';
import icon_twitter from '../../../assets/images/social/social_twitter.svg';
import icon_linkedin from '../../../assets/images/social/social_linkedin.svg';

import './NavigationBottom.css';



const NavigationBottom = props => {

    const loggedInView = () => {
        return (
            <footer id="devp-footer" className="container bg-dark devp-footer">
                <div className="d-flex flex-wrap py-4 px-2 justify-content-between">
                    <div className="align-self-center">
                        <ul className="list-unstyled list-inline m-0">
                            <li className="list-inline-item">
                                <a target="_blank" href={URL_ADDRESSES.EXTERNAL_MAGENTO_HOME}>
                                <img src={magento_logo} className="magento-logo" alt="Magento"/>
                            </a>
                            </li>
                            <li className="list-inline-item">
                                <a target="_blank" href={URL_ADDRESSES.EXTERNAL_MAGENTO_HOME}>
                                    <div className="navigation-bottom-text">&copy; {(new Date().getFullYear())} All Rights Reserved.</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="align-self-center">
                        <ul className="list-unstyled list-inline m-0 marketplace-legal-links">
                            <li className="list-inline-item"><a href={URL_ADDRESSES.EXTERNAL_MARKETPLACE}>Marketplace</a></li>
                            <li className="list-inline-item"><a href={URL_ADDRESSES.EXTERNAL_MAGENTO_PRIVACY}>Privacy</a></li>
                            <li className="list-inline-item text-capitalize"><a href="#" id="openPrivacyModal" onClick="adobePrivacy.showConsentPopup();">Cookie Preferences</a></li>
                            <li className="list-inline-item">
                                <div style={{color: 'white'}}>
                                    <a href={URL_ADDRESSES.EXTERNAL_MARKETPLACE_TERMS_MASTER}>Master </a> &
                                    <a href={URL_ADDRESSES.EXTERNAL_MARKETPLACE_TERMS_DEVELOPMENT}> Developer </a> Terms
                                </div>
                            </li>
                            <li className="list-inline-item"><a href={URL_ADDRESSES.EXTERNAL_TRADEMARK_FAQ}>Trademark FAQs</a></li>
                        </ul>
                    </div>
                    <div className="align-self-center">
                        <ul className="list-unstyled list-inline m-0">
                            <li className="list-inline-item">
                                <a target="_blank" href={URL_ADDRESSES.EXTERNAL_SOCIAL_FACEBOOK}>
                                    <img src={icon_facebook} className="social-media-img" alt="facebook"/>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a target="_blank" href={URL_ADDRESSES.EXTERNAL_SOCIAL_TWITTER}>
                                    <img src={icon_twitter} className="social-media-img" alt="twitter"/>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a target="_blank" href={URL_ADDRESSES.EXTERNAL_SOCIAL_LINKED_IN}>
                                    <img src={icon_linkedin} className="social-media-img" alt="linkedIn"/>
                                </a>
                            </li>
                        </ul> 
                    </div>
                </div>
            </footer>
        )
    };

    return loggedInView();
};

NavigationBottom.propTypes = {
    userData : PropTypes.object.isRequired
};

export default NavigationBottom;
