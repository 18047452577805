//*Note*//
/*
    to use this reducer, your view component can see if this `state` has a length [of greater than 0] or you could
    see if the specific action you called is contained inside this state and show a loading screen or not.
 */
import { FULFILLED, PENDING, REJECTED} from 'redux-promise-middleware';

export default (state = [], {type}) => {
    switch (true) {
        case type.endsWith(`_${PENDING}`) : {
            state = [...state, type.slice(0, -8)]; //-8 is the 8 characters in `_PENDING`
            break;
        }
        case type.endsWith(`_${REJECTED}`) : {
            //this is commented out because if something is rejected, we should debug why,  we can discuss this.//JRP//
            //but this would be the line if we want to 're-enable' the view even if a call is rejected//
            //state = state.filter(actionType => (actionType !== type.slice(0, -9))); //-9 is the 9 characters in `_REJECTED`
            break;
        }
        case type.endsWith(`_${FULFILLED}`) :
        default : {
            //TODO//JRP//
            /*
                This doesnt account for each single instance of an action, just that an action was performed and its
                been returned.  If this is an error in the future, then we'll have to look into identifying each
                specific instance of the action.

                This *could* (but maybe not??) be an issue if the app does [for instace] a `GET_USER_PRODUCT` call with
                a type of `extensions`, then does another `GET_USER_CALL` with a type of `theme`, *AND* that second call
                is made before the first one is returned, it could cause a race condition.  I really don't see that
                happening, but it's a thought to keep in mind//JRP//
             */
            state = state.filter(actionType => (actionType !== type.slice(0, -10))); //-10 is the 10 characters in `_FULFILLED`
        }
    }

    return state;
};