import React, { Component } from "react";

import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
    getMessageAnnouncements,
    getMessagePolicies,
} from "../../actions/messages";
import { URL_ADDRESSES } from "../../utils/constants/URL_Addresses";
import LoadingHOC from "../../utils/loadingHOC";
//view components
import Notifcations from "./components/Notifcations";
import { messagesSelector } from "./selectors";

import "./HelpCenter.css";

class HelpCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    handleTabClick = (index) => {
        if (this.state.tabIndex !== index) {
            return this.setState({ tabIndex: index });
        }
    };

    render() {
        const { announcments, policies } = this.props;
        return (
            <Tabs className="row flex-nowrap flex-sm-row flex-column max-width m-4 border bg-white bs-body-section"
                    selectedTabClassName="product-tabs-selected"
                    selectedTabPanelClassName="container"
                    selectedIndex={this.state.tabIndex}
                    onSelect={(index)=>{this.handleTabClick(index)}} >
                <TabList className="m-0 p-0 border-right product-tabs-tab-list" style={{minWidth : '200px'}} >
                    <Tab className="product-tabs-tab">Policies</Tab>
                    <Tab className="product-tabs-tab">Announcements</Tab>
                    <div className="product-tabs-tab"><a href={URL_ADDRESSES.EXTERNAL_SUPPORT} rel="noopener noreferrer" target="_blank">Support</a></div>
                    <div className="product-tabs-tab"><a href={URL_ADDRESSES.EXTERNAL_USER_GUIDELINES} rel="noopener noreferrer" target="_blank">Getting Started</a></div>
                    <div className="product-tabs-tab"><a href={URL_ADDRESSES.EXTERNAL_API} rel="noopener noreferrer" target="_blank">API</a></div>
                </TabList>
                <TabPanel>
                    <Notifcations header="Policies" idName="policy_id" notifications={policies} />
                </TabPanel>
                <TabPanel>
                    <Notifcations header="Announcements" idName="announcement_id" notifications={announcments} />
                </TabPanel>
            </Tabs>
        );
    }
}

const mapStateToProps = (store, ownProps) => {
  const { isLoaded, policies, announcments } = messagesSelector(store);

  return {
      isLoaded,
      policies,
      announcments,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMessageAnnouncements: () => dispatch(getMessageAnnouncements()),
        getMessagePolicies: () => dispatch(getMessagePolicies()),
    };
};

const LoadComponent = LoadingHOC({
    wait: 1500,
    loaderWithMinHeight: true,
    fetchList: ["getMessageAnnouncements", "getMessagePolicies"],
})(HelpCenter);

export default connect(mapStateToProps, mapDispatchToProps)(LoadComponent);
