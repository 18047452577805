import { FULFILLED } from 'redux-promise-middleware';
import { combineReducers} from 'redux';
import { LOCATION_CHANGE } from 'react-router-redux';

import { NOTIFICATION_LEVELS,
         NOTIFICATION_ACTIONS,
         USER_ACTIONS,
         USER_ACCESS_KEYS_ACTIONS,
         USER_API_KEYS_ACTIONS} from '../utils/constants/ActionTypes';


//TODO//initial state should probably have a list for each notification type
/*
const initialState = {
    error_list        : [],
    warning_list      : [],
    notification_list : [],
    info_list         : []
};
*/

/**
 *
 * @type {{
 *   action_type: string                //type of action that triggered this notification,
 *   notification_level: number         //level of notification, can be (info, error, warning, notification),
 *   notification_message: string       //message to show on screen,
 *   payload_status: string             //status text that comes back from an API call,
 *   show_close_button: boolean         //option to show the close button to the user to they may manually close notification,
 *   timer: null                        //length of time to show the notification before it auto closes
 * }}
 */
const baseNotification = {
    action_type          : "",
    notification_level   : NOTIFICATION_LEVELS.NOTIFICATION,
    notification_message : "",
    payload              : "",
    show_close_button    : false,
    timer                : null
};



/*
In the future we should be able to:
 - support multiple notifications, that can get filtered at the component level
 - have each notification on a timer to disappear after a set amount of time
 - incorporate the notification level into the notification on screen
 */

const userNotification = (state = {}, action) => {
    //need to handle for errors, basically if any of the REST calls return a status outside of 2xx, it should error
    if (action.payload && action.payload.isError) {
        return {
            action_type          : action.type,
            notification_level   : NOTIFICATION_LEVELS.ERROR,
            notification_message : action.payload.message + " Please try again",
            payload_status       : action.payload.statusText
        };
    }

    switch (action.type) {
        case LOCATION_CHANGE : {
            //on a change of the URL, clear the notification,  this may change in the future if we need the notification
            // to persists (i.e. user needs to fill out a form or read a TOS//JRP//
            state = {};
            break;
        }
        case NOTIFICATION_ACTIONS.CLEAR_NOTIFICATIONS : {
            state = {};
            break;
        }
        case USER_ACTIONS.UPDATE_USER_PROFILE_DATA:
        case USER_ACTIONS.UPDATE_USER_PROFILE_DATA + "_" + FULFILLED : {
            //TODO// maybe make this an array so it could hold multiple notifications if needed
            state = {
                ...baseNotification,
                action_type          : action.type,
                notification_message : "Profile update was successful.",
                payload_status       : action.payload.statusText
            };
            break;
        }
        case USER_ACCESS_KEYS_ACTIONS.CREATE_USER_ACCESS_KEY + "_" + FULFILLED : {
            state = {
                ...baseNotification,
                action_type          : action.type,
                notification_message : "Access Key (" + action.payload.m2[0].label + ") has been created successfully.",
                payload_status       : action.payload.statusText
            };
            break;
        }
        case USER_ACCESS_KEYS_ACTIONS.UPDATE_USER_ACCESS_KEY + "_" + FULFILLED : {
            state = {
                ...baseNotification,
                action_type          : action.type,
                notification_message : "Access Key (" + action.payload.m2[0].label + ") has been updated successfully.",
                payload_status       : action.payload.statusText
            };
            break;
        }
        case USER_ACCESS_KEYS_ACTIONS.DELETE_USER_ACCESS_KEY + "_" + FULFILLED : {
            state = {
                ...baseNotification,
                action_type          : action.type,
                notification_message : "Access Key (" + action.payload + ") has been deleted successfully.",
                payload_status       : action.payload.statusText
            };
            break;
        }
        case USER_API_KEYS_ACTIONS.CREATE_USER_API_KEY + "_" + FULFILLED : {
            state = {
                ...baseNotification,
                action_type          : action.type,
                //notification_message : "Api Key (" + action.payload.apiKeysData.api_name + ") has been created successfully.",
                notification_message : "The API Access Key has been created successfully.",
                payload_status       : action.payload.statusText
            };
            break;
        }
        case USER_API_KEYS_ACTIONS.REGENERATE_USER_API_KEY + "_" + FULFILLED : {
            state = {
                ...baseNotification,
                action_type          : action.type,
                //notification_message : "Api Key (" + action.payload.apiKeysData.api_name + ") has been regenerated successfully.",
                notification_message : "The API Access Key has been regenerated successfully.",
                payload_status       : action.payload.statusText
            };
            break;
        }
        case USER_API_KEYS_ACTIONS.DELETE_USER_API_KEY + "_" + FULFILLED : {
            state = {
                ...baseNotification,
                action_type          : action.type,
                //notification_message : "Api Key (" + action.payload.apiKeysData.api_name + ") has been deleted successfully.",
                notification_message : "The API Access Key has been deleted successfully.",
                payload_status       : action.payload.statusText
            };
            break;
        }
        default : {
            return state;
        }
    }

    return state;
};

const productSubmissionNotification = (state = {}, action) => {
    switch (action.type) {
        case NOTIFICATION_ACTIONS.PRODUCT_SUBMIT_SUCCESS_NOTIFICATION: {
            const cloneState = Object.assign({}, state);
            cloneState[action.payload.submission_id] = action.payload.message;
            return cloneState;
        }
        case NOTIFICATION_ACTIONS.CLEAR_PRODUCT_SUBMIT_SUCCESS_NOTIFICATION: {
            const cloneState = Object.assign({}, state);
            Object.keys(cloneState).map((key)=>{
                //if key has action.key
                if(key.includes(action.payload)) {
                    delete(cloneState[key]);
                }
                return key;
            });
            return cloneState;
        }
        default : {
            return state
        }
    } 
};

export default combineReducers({
    userNotification,
    productSubmissionNotification,
});
