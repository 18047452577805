import { NOTIFICATION_ACTIONS } from '../utils/constants/ActionTypes';

export const clearNotifications = () => ({
    type    : NOTIFICATION_ACTIONS.CLEAR_NOTIFICATIONS
});

export const errorNotification = error => ({
    type : NOTIFICATION_ACTIONS.ERROR_NOTIFICATION,
    error
});

export const clearErrorNotification = error => ({
    type : NOTIFICATION_ACTIONS.CLEAR_ERROR_NOTIFICATION,
    error
});

export const formError = (formName, id, error) => {
    return {
        type    : NOTIFICATION_ACTIONS.FORM_ERROR,
        payload : {
                    [`${formName}_${id}`]:{error}
                }
    }
}

export const clearFormError = (formName, submission_id) => ({
    type    : NOTIFICATION_ACTIONS.CLEAR_FORM_ERROR,
    key : `${formName}_${submission_id}`
});

export const clearProductFormErrors = submission_id => ({
    type    : NOTIFICATION_ACTIONS.CLEAR_PRODUCT_FORM_ERRORS,
    submission_id: submission_id,
});

export const productSubmitSuccessNotification = (submission_id, message) => ({
    type    : NOTIFICATION_ACTIONS.PRODUCT_SUBMIT_SUCCESS_NOTIFICATION,
    payload: {submission_id, message},
});

 export const clearProductSubmitSuccessNotification = (submission_id) => ({
    type    : NOTIFICATION_ACTIONS.CLEAR_PRODUCT_SUBMIT_SUCCESS_NOTIFICATION,
    payload: submission_id,
}) 