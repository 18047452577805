import { SSO_PAGE_REDIRECTS } from '../utils/constants/RestCallValues';
import { URL_ADDRESSES, REST_URLS_USER_PROFILE_API, REST_URLS_USER_ENV_API } from '../utils/constants/URL_Addresses';
import { FETCH_GET_OPTIONS, FETCH_PUT_OPTIONS } from '../utils/constants/RestHelpers';
import { PROMISE_ERROR_CATCH, PARSE_JSON_AND_CHECK_STATUS_CODE } from '../utils/constants/ServerResponseStatusCheck'

import { clearCookies } from './cookies';

import { historyStore } from '../utils/store';


export const login = () => {
    let onSuccessURL = historyStore.location.pathname;

    //set the `onSuccessURL` to either a default of home, or to the current deep-link URL for after successful login
    onSuccessURL = (onSuccessURL === URL_ADDRESSES.INTERNAL_HOME) ? URL_ADDRESSES.INTERNAL_EXTENSIONS : onSuccessURL;

    //replace the backslash (`/`) in the URL with its unicode equivalent (`%2F`)
    window.location.href = URL_ADDRESSES.INTERNAL_HOME_SIGNIN + ("?" + SSO_PAGE_REDIRECTS.ON_SUCCESS + onSuccessURL + "&" + SSO_PAGE_REDIRECTS.ON_FAILURE + URL_ADDRESSES.INTERNAL_HOME).replace(/\//gi, "%2F");
};


/**
 * clears users cookies, then navigates to SSO logout URL
 */
export const logout = () => {
    //clear current cookies for this domain
    clearCookies();

    //redirect user to the SSO logout url
    window.location.href = URL_ADDRESSES.INTERNAL_HOME_SIGNOUT;
};


/**
 * method to make an API call to retrieve the user data from the backend services
 *
 * @param {boolean} summary: to pass as a URL param in case we wanted a summarized user profile returned
 * @internal param isnt really being used right now, but could be in the future
 *
 * @returns {Promise<any>} should resolve to a returned json object with updated data from API
 */
export const getUserData = (summary = false) => new Promise((resolve, reject) => {
    fetch(`${REST_URLS_USER_PROFILE_API.GET_USER_PROFILE}${(summary ? "?style=summary" : "")}`, FETCH_GET_OPTIONS)
        .then(PARSE_JSON_AND_CHECK_STATUS_CODE)
        .then(resolve).catch(reject)
}).catch(PROMISE_ERROR_CATCH);


/**
 * method to make an API call and update the user data on the backend
 *
 * @param {json} data: object containing user data
 *
 * @returns {Promise<any>} should resolve to a returned json object with updated data from API
 */
export const updateUserData = data => new Promise((resolve, reject) => {
    fetch(REST_URLS_USER_PROFILE_API.PUT_USER_PROFILE, {...FETCH_PUT_OPTIONS, body:JSON.stringify(data)})
        .then(PARSE_JSON_AND_CHECK_STATUS_CODE)
        .then(resolve).catch(reject)
}).catch(PROMISE_ERROR_CATCH);

/**
 * method to make an API call to retrieve user environment
 */
export const getUserEnv = () => new Promise((resolve, reject) => {
    fetch(REST_URLS_USER_ENV_API.GET_USER_ENV, FETCH_GET_OPTIONS)
        .then(PARSE_JSON_AND_CHECK_STATUS_CODE)
        .then(resolve).catch(reject)
}).catch(PROMISE_ERROR_CATCH);
