
export const SSO_SIGN_IN = Object.freeze({
    COOKIE_TOKEN_NAME              : 'devp_ust',
    COOKIE_MAGE_ID_NAME            : 'devp_mageid',
    COOKIE_SHOW_EXTENSION_WORKFLOW : 'devp_ext_workflow',
    COOKIE_SHOW_THEME_WORKFLOW     : 'devp_theme_workflow'
});



export const COOKIE_VALUES = Object.freeze({
    COOKIE_MAGE_EXTENSION_PROCESS : 'devp_ext_process',
    COOKIE_MAGE_THEMES_PROCESS    : 'devp_themes_process'
});



export const SSO_PAGE_REDIRECTS = Object.freeze({
    ON_SUCCESS : 'on_success=',
    ON_FAILURE : 'on_failure='
});



export const TAX_REVIEW_STATUS_VALUES = Object.freeze({
    NO_DOCUMENTS_UPLOADED     : 0,
    UPLOADED_BUT_NEEDS_REVIEW : 1,
    UPLOADED_AND_REVIEWED     : 2
});