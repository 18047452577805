import { combineReducers} from 'redux';
import { MESSAGES_ACTIONS } from '../utils/constants/ActionTypes';


const policyNotifications = (state = [], action) => {
    switch (action.type) {
        case MESSAGES_ACTIONS.GET_POLICIES : {
            state = action.payload
            break;
        }
        default : {
            return state
        }
    }
    return state;
};

const announcementNotifications = (state = [], action) => {
    switch (action.type) {
        case MESSAGES_ACTIONS.GET_ANNOUNCMENTS : {
            state = action.payload
            break;
        }
        default : {
            return state
        }
    } 
    return state;
};

export default combineReducers({
    announcementNotifications,
    policyNotifications,
});
