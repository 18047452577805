import React from "react";
import {shape, string, array} from 'prop-types';

const NotifictionListItem = ({ notification }) => {
    return (
        <li className="list-group-item">
            <h3 className="magento-h6 text-dark">{notification.title}</h3>
            <p className="mr-5 text-dark" dangerouslySetInnerHTML={{ __html: notification.text }} />
        </li>
    );
};

NotifictionListItem.propTypes = {
    notifications: shape({
        title: string,
        text: string,
    })
}


const NotificationList = ({ notifications, idName, header }) => {
    return (
            <ul id="notifications" className="list-group list-group-flush">
                {notifications.map(notification => {
                    return (
                        <NotifictionListItem
                            key={notification[idName]}
                            notification={notification}
                        />
                    );
                })}
            </ul>
    );
};

NotificationList.propTypes = {
    idName: string.isRequired,
    notifications: array,
}

NotificationList.defaultProps = {
    notifications: [],
}

export default NotificationList;
