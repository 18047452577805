import { POLLING } from '../constants/AppConstants';

const TYPE = POLLING;

const cue = {};
const polling = store => next => action => {
    if (!action || !action.type === TYPE || !action.meta || !action.meta.key ) {
        return next(action)
    }
        const { meta } = action;
        const dispatch = store.dispatch;
        const interval= meta.hasOwnProperty('interval')? meta.interval : 5000;
        if(meta.hasOwnProperty("cancel")){
            window.clearInterval(cue[meta.key].intervalId);
            delete cue[meta.key];
            return;
        }
        const { actionCall, key} = meta;
   
        const cueKey = `${key}`;
        const intervalId = window.setInterval(
            () => {
                dispatch(actionCall)
              }
              ,interval);

        cue[cueKey]={
            intervalId
        }
  };

export default polling;
