export const URL_ADDRESSES = Object.freeze({

    //Home Page
    INTERNAL_HOME                   : '/',
    INTERNAL_HOME_HOME              : '/home',
    INTERNAL_HOME_INDEX             : '/index',

    //SSO
    INTERNAL_HOME_SIGNIN            : '/signin',
    INTERNAL_HOME_LOGIN             : '/login',
    INTERNAL_HOME_LOGON             : '/logon',
    INTERNAL_HOME_SIGNOUT           : '/signout',
    INTERNAL_HOME_SIGNOFF           : '/signoff',
    INTERNAL_HOME_LOGOUT            : '/logout',
    INTERNAL_HOME_LOGOFF            : '/logoff',

    //Extensions
    INTERNAL_EXTENSIONS             : '/extensions/',
    INTERNAL_EXTENSIONS_NO_S        : '/extension',
    
    //Apps
    APPS_SUBMISSION_EXTERNAL        : 'https://developer.adobe.com/distribute/home', //Used to redirect to app submission on Developer Console
    INTERNAL_APPS                   : '/apps/',
    INTERNAL_APPS_NO_S              : '/app',

    //Reports

    INTERNAL_REPORTS_SALES          : '/reports-sales',
    INTERNAL_REPORTS_ANALYTICS      : '/reports-analytics/',

    //Shared Packages
    INTERNAL_SHARED_PACKAGES        : '/sharedpackages/',
    INTERNAL_SHARED_PACKAGES_NO_S   : '/sharedpackage',
    INTERNAL_SHARED_PACKAGES_SHORT  : '/shareds',
    INTERNAL_SHARED_PACKAGES_VERSION_DETAILS : '/sharedpackage-details/:submissionId',
    INTERNAL_SHARED_PACKAGES_VERSION_GRID : '/sharedpackage-versions/:url_key',
    //Themes
    INTERNAL_THEMES                 : '/themes/',
    INTERNAL_THEMES_NO_S            : '/theme',

    //Account Information
    INTERNAL_ACCOUNT                : '/account',
    INTERNAL_ACCOUNT_WITH_S         : '/accounts',
    INTERNAL_ACCOUNT_INFORMATION    : '/accountinformation',
    INTERNAL_INFORMATION            : '/information',
    INTERNAL_INFORMATION_SHORT      : '/info',
    INTERNAL_ACCOUNT_SETTINGS       : '/settings',
    INTERNAL_ACCOUNT_SETTINGS_NO_S  : '/setting',

        //Account Information > Sub routes
    INTERNAL_ACCOUNT_KEYS           : '/keys',
    INTERNAL_API_KEYS               : '/apikeys',
    INTERNAL_ACCOUNT_PROFILE        : '/profile',
    INTERNAL_PROFILE_TAX_FORMS      : '/taxforms',


    INTERNAL_PRODUCT_VERSIONS                     : 'versions',
    INTERNAL_PRODUCT_DETAILS                      : '/details',
    INTERNAL_SHARED_PACKAGE_DETAILS               : '/sharedpackage-details', // TODO: we will need to use the same routing urls. Either Ext/Themes will need to change or SP
    INTERNAL_PRODUCT_TECHNICAL_SUBMISSION         : '/technical_submission',
    INTERNAL_PRODUCT_MARKETING_EXTENSION_DESC     : '/extension_description',
    INTERNAL_PRODUCT_MARKETING_IMAGES             : '/images_and_videos',
    INTERNAL_PRODUCT_MARKETING_COMPATIBILITY      : '/compatibility',
    INTERNAL_PRODUCT_MARKETING_PRICING            : '/pricing',
    INTERNAL_PRODUCT_MARKETING_EXTENSION_SUPPORT  : '/extension_support',
    INTERNAL_PRODUCT_MARKETING_ADDITIONAL_DETAILS : '/additional_details',
    INTERNAL_PRODUCT_MARKETING_DOCUMENTATION      : '/documentation_and_resources',
    INTERNAL_PRODUCT_SUBMISSION_REPORTS           : '/test_reports',

    INTERNAL_PREVIEW_PRODUCT                      : '/preview/:submissionId',

    INTERNAL_TAX_FORM_EMAIL                       : 'Grp-magento-marketplace-forms@adobe.com',

    INTERNAL_HELP_CENTER              : '/learn',
    INTERNAL_HELP_POLICIES            : '/policies',
    INTERNAL_HELP_RELEASE_NOTES       : '/help-release-notes',
    INTERNAL_HELP_NOTIFICATION        : '/help-notification',

    //External Magento Links
    EXTERNAL_MAGENTO_HOME             : 'https://business.adobe.com/products/magento/magento-commerce.html',
    EXTERNAL_ABOUT_US                 : 'https://business.adobe.com/products/magento/magento-commerce.html',
    EXTERNAL_BECOME_A_COMMERCE_PARTNER: 'https://business.adobe.com/products/magento/partners.html',
    EXTERNAL_BECOME_AN_ADOBE_PARTNER  : 'https://www.adobe.com/partners.html',
    EXTERNAL_BENEFITS_U               : 'https://business.adobe.com/products/magento/benefits.html',
    EXTERNAL_CAREERS                  : 'https://www.adobe.com/careers.html',
    EXTERNAL_CERTIFICATION            : 'https://u.magento.com/certification',
    EXTERNAL_COMMUNITY                : 'https://developer.adobe.com/open/magento',
    EXTERNAL_CONTACT_US               : 'https://business.adobe.com/products/magento/get-demo.html',
    EXTERNAL_CREATE_PRODUCT_EXAMPLE   : 'https://developer.adobe.com/commerce/marketplace/guides/sellers/extension-create/',
    EXTERNAL_DEV_DOCS                 : 'https://developer.adobe.com/commerce/',
    EXTERNAL_ENTERPRISE               : 'https://magento.com/?enterprise&todo',
    EXTERNAL_MAGENTO_MY_ACCOUNT       : 'https://account.magento.com/customer/account/',
    EXTERNAL_MAGENTO_PARTNER_PORTAL   : 'https://partners.magento.com/portal/customer/account/',
    EXTERNAL_MAGENTO_UPGRADE_PARTNER_STATUS : 'https://business.adobe.com/products/magento/partners.html',
    EXTERNAL_MAGENTO_PRIVACY          : 'https://www.adobe.com/privacy.html',
    EXTERNAL_MARKETPLACE              : 'https://commercemarketplace.adobe.com/',
    EXTERNAL_MARKETPLACE_EXTENSIONS   : 'https://commercemarketplace.adobe.com/extensions.html',
    EXTERNAL_MARKETPLACE_PARTNERS     : 'https://commercemarketplace.adobe.com/partners.html',
    EXTERNAL_MARKETPLACE_THEMES       : 'https://commercemarketplace.adobe.com/themes.html',
    EXTERNAL_MARKETPLACE_USER_GUIDE   : 'https://developer.adobe.com/commerce/marketplace/guides/sellers',
    EXTERNAL_PRESS                    : 'https://news.adobe.com/home',
    EXTERNAL_PROCESS_OVERVIEW         : 'https://developer.adobe.com/commerce/marketplace/guides/sellers/',
    EXTERNAL_REGULATIONS              : 'https://magento.com/?regulations&todo',
    EXTERNAL_SUPPORT                  : 'https://marketplacesupport.magento.com',
    EXTERNAL_TRADEMARK_FAQ            : 'https://www.adobe.com/legal/permissions.html',
    EXTERNAL_TRAINING                 : 'https://learning.adobe.com/catalog.html',
    EXTERNAL_USER_GUIDELINES          : 'https://developer.adobe.com/commerce/marketplace/guides/sellers',
    EXTERNAL_USER_GUIDES              : 'https://developer.adobe.com/commerce/marketplace/guides/sellers/seller-overview',
    EXTERNAL_VIDEO_TUTORIAL           : 'https://experienceleague.adobe.com/docs/commerce-learn/tutorials/overview.html?lang=en',
    EXTERNAL_MAGENTO_MARKETPLACE      : 'https://commercemarketplace.adobe.com',
    EXTERNAL_MARKETPLACE_PROFILE      : 'https://developer.magento.com/customer/account/edit/',
    EXTERNAL_VERSIONING_GUIDLINES     : 'https://devdocs.magento.com/guides/v2.3/extension-dev-guide/versioning/',
    EXTERNAL_VERSIONING_PROCESS       : 'https://developer.adobe.com/commerce/marketplace/guides/sellers/submit-for-review',
    EXTERNAL_MARKETPLACE_SELLER_GUIDE : 'https://developer.adobe.com/commerce/marketplace/guides/sellers/seller-overview',
    EXTERNAL_MARKETPLACE_TERMS_MASTER : 'https://commercemarketplace.adobe.com/legal/terms/master-terms',
    EXTERNAL_MARKETPLACE_TERMS_DEVELOPMENT : 'https://commercemarketplace.adobe.com/legal/terms/development-terms',
    EXTERNAL_PACKAGE_GUIDELINES       : 'https://developer.adobe.com/commerce/php/development/package/component',
    EXTERNAL_M1_PACKAGE_GUIDELINES    : 'https://docs.magento.com/marketplace/user_guide/sellers/packaging-v1x-extensions.html',
    EXTERNAL_LEARN_MORE_PARTNER       : 'https://magento.com/sites/default/files/Xcelerate_Extension_Builder',
    EXTERNAL_MARKETING_GUIDE          : 'https://developer.adobe.com/commerce/marketplace/guides/sellers/marketing-review-guidelines',
    EXTERNAL_MARKETING_EXTENSION_LONG_DESCRIPTION : 'https://developer.adobe.com/commerce/marketplace/guides/sellers/product-descriptions',
    EXTERNAL_MARKETING_TECHNICAL_LICENSE_TYPES : 'https://developer.adobe.com/commerce/marketplace/guides/sellers/submit-for-technical-review',
    EXTERNAL_MARKETING_TECHNICAL_GUIDELINES : 'https://developer.adobe.com/commerce/marketplace/guides/sellers/technical-review-guidelines',
    EXTERNAL_TECHNICAL_GUIDELINES : 'https://github.com/magento/magento-coding-standard/wiki/Magento-Marketplace-Extensions-Verification',
    EXTERNAL_API                  : 'https://developer.adobe.com/commerce/marketplace/guides/eqp/v1/',


    //social media links for magento
    EXTERNAL_SOCIAL_FACEBOOK        : 'https://www.facebook.com/adobecommerce/',
    // EXTERNAL_SOCIAL_GOOGLE          : 'https://plus.google.com/+magento/',
    EXTERNAL_SOCIAL_LINKED_IN       : 'https://www.linkedin.com/company/adobe-commerce/',
    EXTERNAL_SOCIAL_TWITTER         : 'https://twitter.com/adobecommerce/',
    EXTERNAL_SOCIAL_YOUTUBE         : 'https://www.youtube.com/user/magentocommerce/featured',

    // Tax form links
    EXTERNAL_IRS_TAX_FORMS          : 'https://www.irs.gov/forms-instructions',

    EXTENSION_HTML                  : '.html',

    //Pages for Adobe Launch Metadata Submission Portal
    EXTERNAL_ADOBE_LAUNCH           : '/launch',
    INTERNAL_ADOBE_LAUNCH           : '/launch-internal',
    ADOBE_LAUNCH_ACKNOWLEDGEMENT    : '/thanks',

});

export const URL_PARAMS = Object.freeze({
    NAVIGATION_KEY : "@@navigation_key@@",
    URL_KEY        : "/:url_key",
    SUBMISSION_ID  : "/:submission_id",
    TYPE           : "?type=",
    AS_ADMIN       : "?as_admin=true"
});

export const REST_URLS_USER_PROFILE_API = Object.freeze({
    GET_USER_PROFILE : '/users/profile',
    PUT_USER_PROFILE : '/users/profile',
});

export const REST_URLS_USER_ENV_API = Object.freeze({
    GET_USER_ENV : '/app/environment'
});


export const REST_URLS_USER_ACCESS_KEYS_API = Object.freeze({
    GET_USER_ACCESS_KEYS   : '/users/keys', // /users/keys?type=all,m1,m2
    CREATE_USER_ACCESS_KEY : '/users/keys',
    UPDATE_USER_ACCESS_KEY : '/users/keys/',
    DELETE_USER_ACCESS_KEY : '/users/keys',
});

export const REST_URLS_USER_API_KEYS_API = Object.freeze({
    GET_USER_API_KEYS   : '/users/apiCredentials',
    CREATE_USER_API_KEY : '/users/apiCredentials',
    REGENERATE_USER_API_KEY : '/users/apiCredentials/regenerate',
    DELETE_USER_API_KEY : '/users/apiCredentials/',
});

export const REST_URLS_FILE_UPLOADS_API = Object.freeze({
    GET_FILE_UPLOADS    : '/files/uploads/',
    POST_FILE_UPLOADS   : '/files/uploads/',
    DELETE_FILE_UPLOADS : '/files/uploads/'
});

export const REST_URLS_PRODUCT_API = Object.freeze({
    GET_USER_PRODUCT_LIST               : "/products/packages",
    GET_USER_PRODUCT_LIST_BY_URL_KEY    : "/products/packages?url_key[]=",
    GET_USER_PRODUCT_ITEM_BY_ID         : "/products/packages/", // /products/packages/:id
    POST_USER_PRODUCT_ITEM              : "/products/packages",
    PUT_USER_PRODUCT_ITEM               : "/products/packages",
    SORT_USER_PRODUCT                   : "&sort=",
    GET_PRODUCT_VERSION_COMPATIBILITIES : "/attributes/versionCompatibilities", // products/packages/:submission_id/attributes/versionCompatibilities
    GET_PRODUCT_CATEGORIES              : "/products/packages/attributes/categories",
    GET_PRODUCT_TEST_REPORTS            : "/status",  // /products/packages/:subId/status
    GET_PRODUCT_PREVIEW                 : "/preview", // /products/packages/:subId/preview
    GET_LIVE_PRODUCTS                   : "/products/packages?eqp_status.overall=released_to_store&sort=+sku&limit=-1"
});

export const REST_URLS_VENDOR_API = Object.freeze({
    GET_VENDOR_EXISTS_CHECK_BY_NAME : '/users/vendorExists/' // /users/vendorExists/:vendorName
});

export const REST_URLS_REPORT_API = Object.freeze({
    GET_USER                         : "/users/",
    GET_PRODUCT_DETAILS_VIEW         : "/reports/pageviews", //"/users/:mageId/reports/pageviews"
    GET_CATEGORIES_VIEW              : "/reports/pageviews/categories",
    GET_MARKETPLACE_PAGES_VIEWS      : "/reports/metrics/sessions",
    GET_PAYOUTS_REPORT               : "/reports/payouts", //"/users/:mageId/reports/payouts"
    GET_SALES_REPORT                 : "/reports/sales",
    GET_REFUND_REPORT                : "/reports/refunds",
    GET_SALES_PERFORMANCE_REPORT     : "/reports/totals",
    SORT_REPORT                      : "?sort=",
    FORMAT_CSV                       : "?format=csv",
    DOWNLOAD_PAYOUTS_REPORT          : "/downloads/",

});

export const JSON_PRODUCT_TYPE_TO_URL_PATH = Object.freeze({
    extension         : "extensions",
    theme             : "themes",
    app               : "apps",
    shared_package    : "sharedpackages",
});

// Messages (HelpCenter)
export const REST_URLS_MESSAGES_API = Object.freeze({
    GET_POLICIES                 : '/messages/policies',
    GET_ANNOUNCEMENTS            : '/messages/announcements',
});

export const REST_URLS_ADOBE_LAUNCH_API = Object.freeze({
    CREATE_MARKETING_DATA        : '/reactor/extensionPackages/meta/', //'/reactor/extensionPackages/meta/:id',
});
