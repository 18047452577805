import React from "react";
import { FileLoadingManagerContextProvider} from "./FileMangagment";

const AppContextProvider = (props) => {
    return (
        <FileLoadingManagerContextProvider>
            {props.children}
        </FileLoadingManagerContextProvider>
    )
}

export default AppContextProvider;