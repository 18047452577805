import {REST_URLS_ADOBE_LAUNCH_API} from "../utils/constants/URL_Addresses";
import {FETCH_PUT_OPTIONS} from "../utils/constants/RestHelpers";
import {PARSE_JSON_AND_CHECK_STATUS_CODE, PROMISE_ERROR_CATCH} from "../utils/constants/ServerResponseStatusCheck";

export const createMarketingData = (data,id) => {
    return new Promise((resolve, reject) => {
        fetch( REST_URLS_ADOBE_LAUNCH_API.CREATE_MARKETING_DATA+id,
            {...FETCH_PUT_OPTIONS, body:JSON.stringify(data)})
            .then(PARSE_JSON_AND_CHECK_STATUS_CODE)
            .then(resolve).catch(reject)
    }).catch(PROMISE_ERROR_CATCH);
};
