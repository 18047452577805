import { combineReducers } from 'redux';
import {FILE_UPLOAD_ACTIONS } from '../utils/constants/ActionTypes';


const filesUploadingByFileId =  (state = {}, action) => {
    switch (action.type) {
        case FILE_UPLOAD_ACTIONS.UPDATE_FILE_UPLOAD : {
            return Object.assign({}, state, action.payload);
        }
        case FILE_UPLOAD_ACTIONS.REMOVE_FILE_UPLOAD : {
            return Object.assign({}, delete(state[action.fileId]));
        }
        default : {
            return state;
        }
    }
};

const productArtifactsFilesUploading =  (state = {}, action) => {
    switch (action.type) {
        case FILE_UPLOAD_ACTIONS.UPDATE_ARTIFACT_LOAD_LIST : {
            let artfifactFileIds = (state[action.artifactId]) ? state[action.artifactId] : [];
            artfifactFileIds.push(action.fileId);
            
            return {...state, ...{[action.artifactId]: artfifactFileIds } };
        }
        case FILE_UPLOAD_ACTIONS.REMOVE_ARTIFACT_LOAD_ITEM : {
            const artfifactsIds = Object.assign({}, state);
            artfifactsIds[action.artifactId] = artfifactsIds[action.artifactId].filter((fileId)=> (fileId !== action.fileId) );
            return artfifactsIds; 
        }
        default : {
            return state;
        }
    }
};

export default combineReducers({
    filesUploadingByFileId,
    productArtifactsFilesUploading,
});