import React from 'react';
import PropTypes from 'prop-types';

import { NOTIFICATION_LEVELS } from '../../utils/constants/ActionTypes';

import { MdWarning, MdInfo, MdCheckCircle, MdClose } from 'react-icons/lib/md';

import style from './NotificationBlock.css';



const NotificationBlock = ({show, text, level, handleNotificationCancel}) => {
    const warningView = () => {
        return <div className="block-info mt-4" style={style}>
            <div className="icon-warning"><MdWarning/></div>
            <div className="custom-text-warning">{text}</div>
        </div>;
    };

    const notificationView = () => {
        return <div className="block-info mt-4" style={style}>
            <div className="icon-notification"><MdCheckCircle/></div>
            <div className="custom-text-notification">{text}</div>
            <div className="close-info cursor-pointer" onClick={handleNotificationCancel}><MdClose/></div>
        </div>
    };

    const infoView = () => {
        return <div className="block-notification mt-4" style={style}>
            <div className="icon-info"><MdInfo/></div>
            <div className="custom-text-info">{text}</div>
            <div className="close-info cursor-pointer" onClick={handleNotificationCancel}><MdClose/></div>
        </div>
    };

    const errorView = () => {
        return <div className="block-error mt-4 ml-2 mr-2" style={style}>
            <div className="icon-warning"><MdWarning/></div>
            <div>{text}</div>
        </div>;
    };


    const getTextView = () => {
        if (text !== "") {
            if (NOTIFICATION_LEVELS.WARNING === level) {
                return warningView();
            } else if (NOTIFICATION_LEVELS.NOTIFICATION === level) {
                return notificationView();
            } else if (NOTIFICATION_LEVELS.INFO === level) {
                return infoView();
            } else if (NOTIFICATION_LEVELS.ERROR === level) {
                return errorView();
            }
        }

        return "";
    };

    return getTextView();
};

NotificationBlock.propTypes = {
    text : PropTypes.object.isRequired,
    level : PropTypes.oneOf(Object.values(NOTIFICATION_LEVELS)).isRequired,
    handleNotificationCancel : PropTypes.func
};

NotificationBlock.defaultProps = {
    text  : "",
    level : NOTIFICATION_LEVELS.WARNING
};

export default NotificationBlock;
