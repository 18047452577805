import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//Tab Components
import MarketingGuidelinesTab from './tabs/MarketingGuidelinesTab';
import PoliciesAndRegulationsTab from './tabs/PoliciesAndRegulationsTab';
import ProcessOverviewTab from './tabs/ProcessOverviewTab';
import TechnicalRequirementsTab from './tabs/TechnicalRequirementsTab';


const GettingStarted = () => {

    return <div className="landing-page-body-getting-started">
        <div className="landing-page-body-title">Getting Started</div>
        <div className="landing-page-body-getting-started-tabs">
            <Tabs className="landing-page-react-tabs" selectedTabClassName="landing-page-react-tabs__tab--selected"
                  defaultIndex={2}>
                <ProcessOverviewTab/>
            </Tabs>
        </div>
    </div>;
};

export default GettingStarted;