import { FULFILLED } from 'redux-promise-middleware';

import { USER_ACTIONS } from "../utils/constants/ActionTypes";


const initialState = {
    "devp_env":"local",
    "in_sandbox_mode":false
};

const envReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_ACTIONS.GET_USER_ENV_DATA + "_" + FULFILLED: {
            state = {
                ...state,
                ...action.payload
            }
            break;
        }
        default: {
            return state;
        }
    }

    return state;
};

export { envReducer as default, initialState };
