import { createSelector } from 'reselect';
import { getMessageAnnouncementsURL, getMessagePoliciesURL } from '../../utils/urls'


const urlLoadedSelector = (state) => state.app.loadingRequests;

const urlMessageListsSelector = (state ) => {
    return [getMessageAnnouncementsURL(), getMessagePoliciesURL()]
};

const announcmentsSelector = (state) => {
    return state.messages.announcementNotifications;
};

const policiesSelector= (state) => {
    return state.messages.policyNotifications
};

export const messagesSelector = createSelector(
    urlLoadedSelector,
    urlMessageListsSelector,
    announcmentsSelector,
    policiesSelector,
    (urlLoaded,urls, announcments, policies) => {
        const areUrlsLoaded = !urls.find(url=>!urlLoaded.hasOwnProperty(url));
        return {
            isLoaded: areUrlsLoaded,
            announcments,
            policies
        }
    }

)
