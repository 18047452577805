import React, { Fragment } from 'react';
import {URL_ADDRESSES} from "../../../utils/constants/URL_Addresses";
import {Button} from "@react-spectrum/button";

const data = [
    {
        title: "Documentation",
        content:`With thousands of extensions available in Adobe Commerce  Marketplace, 
        it’s critical that your extensions follow our compatibility 
        requirements so that your product can run alongside components 
        and extensions from other developers. 
        Therefore, it’s important that you read the documentation for 
        instructions to ensure that your extension behaves correctly 
        in Adobe Commerce’s modular environment.`,
        btnLink: URL_ADDRESSES.EXTERNAL_PROCESS_OVERVIEW,
        btnLabel: "Read Documentation",
    },
    {
        title: "Video Tutorials",
        content:`These videos offer best practices and tips for creating 
        Adobe Commerce  extensions. We recommend that you follow these 
        suggested standards when developing your extension code.`,
        btnLink: URL_ADDRESSES.EXTERNAL_VIDEO_TUTORIAL,
        btnLabel: "Watch Videos",
    },
    {
        title: "Contribute",
        content:`Join our network of dedicated Adobe Commerce  contributors sharing tips, 
        tricks and best practices. Questions and discussion are welcome but 
        make sure you do a quick search first to see if the topic has already 
        been covered. In addition, always treat the forums and 
        community members with respect.`,
        btnLink: URL_ADDRESSES.EXTERNAL_COMMUNITY,
        btnLabel: "Join the Community",
    },

];

const DeveloperResources = () => {

    return (
        <Fragment>
            <div className="" >
                <div className="col-12 text-center">
                    <h2 className="magento-h2 text-center py-4 text-dark">Developer Resource</h2>
                    <h2 className="text-dark">Everything you need to build and maintain Adobe Commerce  products</h2>
                </div>
            </div>
            
            { data.map((item, index)=>{
                return (
                    <div key={index} className="mb-2" >
                        <div className="col-12 bg-light text-dark p-4" >
                            <h3 className="">{item.title}</h3>
                            <div className="row">
                                <div className="col-12 col-lg-9">
                                    <p className="landing-page-wells">{item.content}</p>
                                </div>
                                <div className="col-12 col-lg-3 d-flex align-items-center">
                                    <Button variant="primary" elementType="a" href={item.btnLink} rel="noopener noreferrer" target="_blank" >{item.btnLabel}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        
        </Fragment>
    );
};

export default DeveloperResources;