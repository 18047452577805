const checkResponseStatusCode = response => {
    if ((response.status >= 200) && (response.status < 300)) {
        if (response.status === 204)
            return response;
        return response.json();
    }


    //need to account for //status code `401 unauthorized`//
    //need to account for //status code `403 forbidden`//
    //need to account for //status code `413 Request Entity Too Large`//
    if ((response.status >= 401) && (response.status <= 403)) {
        //*note*//at this point `responseBody` should equal {code/message} object at bottom of this file in the comments
        return null
    }

    //This happens if the server isnt responding//TODO//display a message to the user from here//JRP//
    if ((response.status >= 500) && (response.status <= 599)) {
        console.error("HTTP Response Error - Probably issue with the server", response);
        return null
    }

    return null
};

export const PARSE_JSON_AND_CHECK_STATUS_CODE = response => {
    const _statusCodeResponse = checkResponseStatusCode(response);

    if (null !== _statusCodeResponse)
        return _statusCodeResponse;

    const error = new Error(`HTTP Error ${response.statusText}`);

    //todo//maybe think of a better way to notify the reducers of an error?//JRP//
    error.isError = true;
    error.status = response.statusText;
    error.response = response;

    throw error;
};

export const PROMISE_ERROR_CATCH = error => {
    console.error("Error Message", error);
    return error;
};

//TODO//implement a check for these server response codes... maybe??? need to coordinate with Backend peeps//JRP//
//{code: 1104, message: "User session has expired"}
//{code: 1101, message: "Missing UST token"} //happens when user hasnt touched app for a while and token expires
//{code: 1201, message: "Requested resource was not found"}
//{code: 1202, message: "Product has been deleted"}
//{code: 1206, message: "Duplicate version number for this product - A version with the number '1.1.14' already exists.", item_id: "SP6", name: "shared_package6", type: "Shared_Package", platform: "M2"}
//{code: 1210, message: "Invalid SKU given. SKU must be of the form 'vendor_name/package_name'"
//{code: 1500, message: "User not found in database"}
//{code: 1501, message: "Failed to decrypt UST"}
//{code: 1605, message: "The composer.json file has invalid JSON.  Please verify the archive."

// on above codes, we should clear the cookies and the user data and navigate back to the landing page