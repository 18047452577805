import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Menu from './ContextMenu/index';

import profile_default_avatar_small from '../../../assets/images/spectrum_avatar.svg';

import { Link, NavLink } from 'react-router-dom';

import { URL_ADDRESSES } from "../../../utils/constants/URL_Addresses";

import { Button } from "@react-spectrum/button";


class NavigationTop extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            collapseState: "collapse navbar-collapse",
        };
        
    }

    handleNavCollapse = (evt) => {
        const { collapseState } = this.state;
        let newCollapseState = collapseState.split(' ');
      
        (newCollapseState.indexOf('in') !== -1 ) ? newCollapseState = [...newCollapseState.filter((val)=>(val !== 'in'))] : newCollapseState.push('in');
        (collapseState.split(' ').indexOf('collapse') !== -1 ) ? newCollapseState = [...newCollapseState.filter((val)=>(val !== 'collapse'))] : newCollapseState.push('collapse');

        this.setState({collapseState: newCollapseState.join(' ')});
    };

    loggedInView = () => {
        const { userData, handleLogout } = this.props;
        return (
            <Fragment>
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <a href={URL_ADDRESSES.APPS_SUBMISSION_EXTERNAL} target="_blank"
                            className="nav-link">Apps</a>
                    </li>
                    <li className="nav-item">
                        <NavLink to={URL_ADDRESSES.INTERNAL_EXTENSIONS}
                            className="nav-link">Extensions</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={URL_ADDRESSES.INTERNAL_THEMES}
                            className="nav-link">Themes</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={URL_ADDRESSES.INTERNAL_SHARED_PACKAGES}
                        className="nav-link">Shared Packages</NavLink>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" data-toggle="dropdown">Reports</a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" href={URL_ADDRESSES.INTERNAL_REPORTS_SALES}>Sales</a>
                            <a className="dropdown-item" href={URL_ADDRESSES.INTERNAL_REPORTS_ANALYTICS}>Analytics</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <NavLink to={URL_ADDRESSES.INTERNAL_HELP_CENTER}
                         className="nav-link">Resources</NavLink>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href={URL_ADDRESSES.EXTERNAL_SUPPORT} rel="noopener noreferrer" target="_blank">
                            Support
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href={URL_ADDRESSES.EXTERNAL_COMMUNITY} rel="noopener noreferrer" target="_blank">
                            Community
                        </a>
                    </li>
                </ul>
                <ul className ="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link">
                            <img src={profile_default_avatar_small} alt="default avatar"/>
                        </a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" data-toggle="dropdown"> {userData.first_name + " " + userData.last_name}</a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" href={URL_ADDRESSES.INTERNAL_ACCOUNT_INFORMATION + URL_ADDRESSES.INTERNAL_INFORMATION}>Account Information</a>
                            <a className="dropdown-item" onClick={handleLogout}>Sign Out</a>
                        </div>
                    </li>
                </ul>
            </Fragment>
        );
    };

    loggedOutView = () => {

        const { handleLogin } = this.props;
        
        return (
            <Fragment>
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            href={URL_ADDRESSES.EXTERNAL_MARKETPLACE}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Marketplace
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            href={URL_ADDRESSES.EXTERNAL_DEV_DOCS}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            DevDocs
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            href={URL_ADDRESSES.EXTERNAL_API}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            API
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            href={URL_ADDRESSES.EXTERNAL_SUPPORT}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Support
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            href={URL_ADDRESSES.EXTERNAL_COMMUNITY}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Community
                        </a>
                    </li>
                </ul>
                <div className="form-inline my-2 my-lg-0">
                    <div className="mr-2">
                        <Button variant="primary" isQuiet="yes" onPress={handleLogin}>Create Account</Button>
                    </div>
                    <div>
                        <Button variant="cta" onPress={handleLogin}>Sign In</Button>
                    </div>
                </div>
            </Fragment>
        );
    };

    render () {
        const { userData } = this.props;

        return (
            <div className="container">
            
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="navbar-brand">
                        <Link to={URL_ADDRESSES.INTERNAL_HOME} id="nav_top_link_logo"><div className="magento-developer-logo"/></Link>
                    </div>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        data-target="#primaryNavBar"
                        onClick={this.handleNavCollapse}
                        >
                             <span class="navbar-toggler-icon"></span>
                    </button>
                    <div className={this.state.collapseState}>
                        {userData.isFullProfile === null ? this.loggedOutView() : this.loggedInView()}
                    </div>
                </nav>
            </div>
        );
    }
}

NavigationTop.propTypes = {
    userData     : PropTypes.object.isRequired,
    handleLogout : PropTypes.func.isRequired,
    handleLogin  : PropTypes.func,
};

export default NavigationTop;
