
import { combineReducers} from 'redux';
import merge from 'lodash/merge';

import { FULFILLED } from 'redux-promise-middleware';

import { PRODUCT_ACTIONS } from '../utils/constants/ActionTypes';

const initialState =  [];

const testReportStatus = (state = initialState, action) => {
    state = !Object.keys(state).length ? initialState:state;

    switch (action.type) {
        case PRODUCT_ACTIONS.GET_PRODUCT_TEST_REPORTS:
        case PRODUCT_ACTIONS.GET_PRODUCT_TEST_REPORTS + "_" + FULFILLED : {
            state = action.payload !== undefined ? action.payload: {};
            break;
        }
        default : {
            return state
        }
    }

    return state;
};

const testReportInitialState = {
}


const testReports = (state=testReportInitialState, action) => {
   
    switch (action.type) {
        case PRODUCT_ACTIONS.GET_PRODUCT_TEST_REPORT:
        case PRODUCT_ACTIONS.GET_PRODUCT_TEST_REPORT + "_" + FULFILLED : {
            const {submissionId, testName, testId, data} = action.payload;
            const newResult = {
                [submissionId]:{
                    [testId]:{
                        [testName]:data,
                    }
                }
            }
            state = merge(state, newResult)
            break;
        }
        default : {
            return state
        }
    }

    return state;
};







export default combineReducers({
    testReportStatus,
    testReports,
});