import { FULFILLED } from "redux-promise-middleware";

import { USER_ACTIONS } from "../utils/constants/ActionTypes";

const initialState = {
    isFullProfile: null,
    mage_id: null,
    adobe_user_id: "",
    email: "",
    extension_share_percent: null,
    first_name: "",
    has_accepted_tos: false,
    has_completed_profile: false,
    hold_payouts: false,
    install_share_percent: null,
    is_company: false,
    last_name: "",
    locale: "en-US",
    partner_level: null,
    payment_info: "",
    payment_type: null,
    personal_profile: {
        bio: "",
        social_media_info: {
            twitter: "",
            stackexchange_url: "",
            facebook_url: "",
            linkedin_url: "",
            github_username: "",
        },
        addresses: [
            {
                address_line_1: "",
                apt_suite_other: "",
                address_line_2: "",
                city: "",
                state: "",
                country: "",
                postal_code: "",
                country_code: "",
                phone: "",
                is_primary: false,
            },
        ],
    },
    company_profile: {
        bio: "",
        social_media_info: {
            twitter: "",
            stackexchange_url: "",
            facebook_url: "",
            linkedin_url: "",
            github_username: "",
        },
        addresses: [
            {
                address_line_1: "",
                apt_suite_other: "",
                address_line_2: "",
                city: "",
                state: "",
                country: "",
                postal_code: "",
                country_code: "",
                phone: "",
                is_primary: false,
            },
        ],
    },
    organizations: [
        {
            org_id: "",
            org_name: "",
        },
    ],
    privacy_policy_url: "",
    action: "submit",
    profile_image_artifact: [],
    screen_name: "",
    show_extension_workflow: true,
    show_theme_workflow: true,
    show_app_workflow: true,
    support_share_percent: null,
    tax_document_url: "",
    tax_id: "",
    tax_review_status: null,
    tax_withhold_percent: 0,
    taxpayer_type: null,
    theme_share_percent: null,
    timezone: "UST",
    tos_accepted_version: null,
    tos_accepted_date: null,
    vendor_name: "",
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_ACTIONS.LOGOUT + "_" + FULFILLED: {
            state = initialState;
            break;
        }
        case USER_ACTIONS.GET_USER_PROFILE_DATA:
        case USER_ACTIONS.GET_USER_PROFILE_DATA + "_" + FULFILLED: {
            state = {
                ...state,
                ...action.payload,
                isFullProfile: true,
            };
            break;
        }
        case USER_ACTIONS.UPDATE_USER_DATA + "_" + FULFILLED:
        case USER_ACTIONS.UPDATE_USER_PROFILE_DATA:
        case USER_ACTIONS.UPDATE_USER_PROFILE_DATA + "_" + FULFILLED: {
            state = {
                ...state,
                ...action.payload,
            };
            break;
        }
        case USER_ACTIONS.GET_USER_DATA: {
            return state;
        }
        default: {
            return state;
        }
    }

    return state;
};

export { userReducer as default, initialState };
