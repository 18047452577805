import { SSO_SIGN_IN } from '../utils/constants/RestCallValues';


/**
 * Has a the name of the cookie being looked for, and searches the browser cookie history.  If no cookie is found, then
 * return null
 *
 * Note: If a cookie has expired, the browser should delete it automatically, so there *shouldn't* be a need to check
 * the expiration date
 *
 * @returns { {mage_id: (Array|{index: number, input: string}), login_token: (Array|{index: number, input: string})}}
 */
export const readCookie = () => {
    let login_token = document.cookie.match(new RegExp(SSO_SIGN_IN.COOKIE_TOKEN_NAME + '=([^;]+)'));
    let mage_id = document.cookie.match(new RegExp(SSO_SIGN_IN.COOKIE_MAGE_ID_NAME + '=([^;]+)'));

    login_token = login_token ? login_token[1] : "";
    mage_id = mage_id ? mage_id[1] : "";

    return {mage_id, login_token}
};

/**
 * Get a list of all the cookies set for the current domain, then split them up and set the expiration date to now,
 * this should cause the browser to automatically delete the cookies
 */
export const clearCookies = () => {
    document.cookie.split(";")
        .forEach((cookieItem) => {
            // Only delete developer portal's cookies . This ensures admin user can stay logged in even if devportal user logs out.
            if (cookieItem.includes('devp_mageid') || cookieItem.includes('devp_ust')) {
                document.cookie = cookieItem.replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            }
        });
};