import { FULFILLED } from 'redux-promise-middleware';

import { REPORT_ACTIONS } from '../utils/constants/ActionTypes';
import { GET_AGGREGATED_DATA, GET_AGGREGATED_CATEGORY_VIEWS_DATA, GET_AGGREGATED_MARKETPLACE_VIEWS_DATA, GET_PRODUCT_GRAPH_DATA } from '../utils/helpers/utilityHelpers';


const initialState = {
    productPageViews : {},
    aggredatedProductPageViews : {},
    categoryViews : {},
    aggregatedCategoryViews : {},
    aggregatedMarketplaceViews : {},
    sales :{},
    refund : {},
    salesPerformance : {},
    payouts : {},
    totalSalesCount:0,
    totalRefundCount:0,
    totalPayoutsCount:0
};

export default (state = initialState, action) => {
    state = !Object.keys(state).length ? initialState:state;

    switch (action.type) {
        case REPORT_ACTIONS.GET_PRODUCT_DETAILS_VIEW + "_" + FULFILLED : {
            state = {
                ...state,
                productPageViews: action.payload !== undefined ? GET_PRODUCT_GRAPH_DATA(action.payload) : {},
                aggredatedProductPageViews: action.payload !== undefined ? GET_AGGREGATED_DATA(action.payload) : {}
            };
            break;
        }
        case REPORT_ACTIONS.GET_CATEGORIES_VIEW + "_" + FULFILLED : {
            state = {
                ...state,
                categoryViews: action.payload !== undefined ? action.payload : {},
                aggregatedCategoryViews: action.payload !== undefined ? GET_AGGREGATED_CATEGORY_VIEWS_DATA(action.payload) : {}
            };
            break;
        }
        case REPORT_ACTIONS.GET_MARKETPLACE_PAGES_VIEWS + "_" + FULFILLED : {
            state = {
                ...state,
                aggregatedMarketplaceViews: action.payload !== undefined ? GET_AGGREGATED_MARKETPLACE_VIEWS_DATA(action.payload) : {}
            };
            break;
        }
        case REPORT_ACTIONS.GET_SALES_REPORT + "_" + FULFILLED : {
            state = {
                ...state,
                sales: action.payload !== undefined ? action.payload : {}
            };
            break;
        }
        case REPORT_ACTIONS.GET_REFUND_REPORT + "_" + FULFILLED : {
            state = {
                ...state,
                refund: action.payload !== undefined ? action.payload : {}
            };
            break;
        }
        case REPORT_ACTIONS.GET_SALES_PERFORMANCE_REPORT + "_" + FULFILLED : {
            state = {
                ...state,
                salesPerformance : action.payload !== undefined ? action.payload : {}
            };
            break;
        }
        case REPORT_ACTIONS.SORT_SALES_REPORT + "_" + FULFILLED : {
            state = {
                ...state,
                sales : action.payload !== undefined ? action.payload : {}
            };
            break;
        }
        case REPORT_ACTIONS.SORT_REFUNDS_REPORT + "_" + FULFILLED : {
            state = {
                ...state,
                refund : action.payload !== undefined ? action.payload : {}
            };
            break;
        }
        case REPORT_ACTIONS.GET_PAYOUTS_REPORT + "_" + FULFILLED : {
            state = {
                ...state,
                payouts: action.payload !== undefined ? action.payload : {}
            };
            break;
        }
        case REPORT_ACTIONS.PAGINATED_SALES_REPORT + "_" + FULFILLED : {
            state = {
                ...state,
                sales: action.payload.json !== undefined ? action.payload.json: {},
                totalSalesCount: action.payload.count !== undefined ? action.payload.count: 0
            };
            break;
        }
        case REPORT_ACTIONS.PAGINATED_REFUNDS_REPORT + "_" + FULFILLED : {
            state = {
                ...state,
                refund: action.payload.json !== undefined ? action.payload.json : {},
                totalRefundCount: action.payload.count !== undefined ? action.payload.count: 0
            };
            break;
        }
        case REPORT_ACTIONS.PAGINATED_PAYOUTS_REPORT + "_" + FULFILLED : {
            state = {
                ...state,
                payouts: action.payload.json !== undefined ? action.payload.json : {},
                totalPayoutsCount: action.payload.count !== undefined ? action.payload.count: 0
            };
            break;
        }
        default : {
            return state
        }
    }

    return state;
};
