import { combineReducers } from 'redux';
import { API_ERROR, NOTIFICATION_ACTIONS } from '../utils/constants/ActionTypes';


const productErrors = (state = [], action) => {
    switch (action.type) {
        case API_ERROR.PRODUCT: {
            state = [...state, action.payload];
            break;
        }
        default : {
            return state
        }
    } 
    return state;
};

const formErrors = (state = {}, action) => {
    
    switch (action.type) {
        case NOTIFICATION_ACTIONS.FORM_ERROR: {
            return {...state, ...action.payload};
        }
        case NOTIFICATION_ACTIONS.CLEAR_FORM_ERROR: {
            const cloneState = Object.assign({}, state);
            delete(cloneState[action.key])
            return cloneState;
        }
        case NOTIFICATION_ACTIONS.CLEAR_PRODUCT_FORM_ERRORS: {
            const cloneState = Object.assign({}, state);
            Object.keys(cloneState).map((key)=>{
                //if key has action.key
                if(key.includes(action.submission_id)) {
                    delete(cloneState[key]);
                }
                return key;
            });
            return cloneState;
        }
        default : {
            return state
        }
    } 
};

const serverError = (state='', action) => {
    switch(action.type) {
        case NOTIFICATION_ACTIONS.ERROR_NOTIFICATION: {
           state = action.error;
            break;
       }
       case  NOTIFICATION_ACTIONS.CLEAR_ERROR_NOTIFICATION: {
           state = '';
           break;
       }
       default: {
           return state;
       }
   }
   return state;
}


export default combineReducers({
    formErrors,
    productErrors,
    serverError,
});