import React, { Fragment } from 'react';

import {Button} from "@react-spectrum/button";

import { URL_ADDRESSES } from "../../../utils/constants/URL_Addresses";

const data  = [
    {
        title:'Apps',
        content: `Adobe Commerce Apps provide merchants with a trusted source to discover
        and purchase high quality apps to accelerate their commerce implementations. 
        Apps are powered by Adobe Developer App Builder (App Builder) that provide developers a
        unified cloud-native extensibility framework for integrating and extending Adobe solutions.`,
        btnLink: URL_ADDRESSES.APPS_SUBMISSION_EXTERNAL,
        btnLabel:"Submit an App"
    },
    {
        title:'Extensions',
        content: `The Adobe Commerce platform can be easily enhanced 
        and extended with the features and functionality merchants need to 
        create differentiated customer experiences. 
        You can develop your own extension and sell it on Adobe Commerce Marketplace`,
        btnLink: URL_ADDRESSES.INTERNAL_EXTENSIONS,
        btnLabel:"Submit an Extension"
    },
    {
        title:'Themes',
        content: `Adobe Commerce application themes enable Adobe Commerce storefronts to have a 
        consistent visual experience. You can build a custom theme and sell it on 
        Adobe Commerce Marketplace.`,
        btnLink: URL_ADDRESSES.INTERNAL_THEMES,
        btnLabel:"Submit a Theme"
    }    
];

const MarketplaceExtensionsTheme = () => {
    return (
        <Fragment>
            <h1 className="magento-h4 text-center text-dark">Adobe Commerce Developer Portal for Marketplace</h1>
            <h2 className="magento-h2 text-center py-4 text-dark">What Can You Build and Sell on Adobe Commerce Marketplace?</h2>
            <div className="row">
                { data.map((item, index)=>{
                    return (
                    <div key={index} className="d-flex align-items-stretch col-sm-12 col-md-6 flex-basis-33">
                        <div className="d-flex flex-column align-items-center text-dark bg-light p-4">
                            <h3 className="magento-h3">{item.title}</h3>
                            <p className="flex-grow-1 landing-page-wells">{item.content}</p>
                            {item.btnLabel == "Submit an App" ?
                                <Button variant="cta" elementType="a" href={item.btnLink} target="_blank">{item.btnLabel}</Button> :
                                <Button variant="cta" elementType="a" href={item.btnLink}>{item.btnLabel}</Button>}
                        </div>
                    </div>
                    );
                })}
            </div>
        </Fragment>
    );
};

export default MarketplaceExtensionsTheme;